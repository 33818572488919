import React, { PropsWithChildren, ReactElement } from 'react'
import styles from './Amount.module.scss'

interface IAmountProps{
    amount : number 
}

const Amount = (props : PropsWithChildren<IAmountProps>,
    ): ReactElement => {
    const {amount} = props
    return (
        <div className={styles.amountWrapper}>
            <h3 className={styles.amountTitle}>Amount to be charged</h3>
            <h2 className={styles.amountCharged}>${amount}</h2>
        </div>
    )
}

export default Amount
