import React, { useState, useEffect, PropsWithChildren, ReactElement } from 'react';
import SkeletonBlock from '../../../components/shared/SkeletonBlock';
import styles from './Invoices.module.scss';
import WebAccountService from "../../../services/WebAccountService";
import Item from "./Item/Item";
import { dateHandler } from '../../../shared/helpers';
import { Advice } from './Advice';
import UnauthorizedModal from '../Shared/UnauthorizedModal';
import { downloadAlt } from '../../../shared/icons'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomInputDate } from '../Shared/CustomInputDate/index'
import Helmet from '../../../components/Helmet';

interface Date {
    dateFrom: any;
    dateTo: any;
}

const Invoices = (props: PropsWithChildren<any>): ReactElement => {

    const [dateData, setDateData] = useState<Date>({
        dateFrom: '',
        dateTo: ''
    });
    const [data, setData] = useState([]);
    const [printSkeleton, setPrintSkeleton] = useState(false);
    const [haveInvoices, setHaveInvoices] = useState(true);
    const [serviceHttpStatusCode, setServiceHttpStatusCode] = useState<number>(0);

    useEffect(() => {
        const currentDate = new Date()
        const currentProps = dateHandler(currentDate);
        const formatCurrentDate = currentProps.fullNumberDate;
        const pastDate = new Date(new Date().setDate(new Date().getDate() - 7));
        const pastProps = dateHandler(pastDate);
        const formatPastDate = pastProps.fullNumberDate;
        setDateData({ ...dateData, dateFrom: pastDate, dateTo: currentDate });
    }, [])

    const handleData = async (event) => {
        try {
            const response = await WebAccountService.invoices(
                {
                    dateFrom: dateData.dateFrom,
                    dateTo: dateData.dateTo
                }
            );
            if (response.success && response.response.length >= 1) {
                setData(response.response);
                setPrintSkeleton(false);
            } else {
                if (response.httpStatusCode !== 401) {
                    setHaveInvoices(false);
                }
                else {
                    setServiceHttpStatusCode(response.httpStatusCode);
                }
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        setPrintSkeleton(true);
        handleData(dateData);
        setHaveInvoices(true);
    }, [dateData])

    const handleChangeStart = (e) => {
        setDateData({ ...dateData, dateFrom: e })
    }

    const handleChangeEnd = (e) => {
        setDateData({ ...dateData, dateTo: e })
    }

    return (
        <>
            <Helmet title={'Invoices'} />
            <div className={`${styles.invoices} print-block`}>
                <div className={styles.container}>
                    <h2 className={styles.title}>Invoices</h2>
                    <div className='print-hidden'>
                        <button className={styles.button} onPointerDown={() => window ? window.print() : null}>{downloadAlt}  Download</button>
                    </div>
                </div>
                <div className={`${styles.inputContainer} print-hidden`}>
                    <div>
                        <label htmlFor="dateFrom" style={{ display: 'block' }}>From</label>
                        <DatePicker
                            selected={dateData.dateFrom}
                            onChange={handleChangeStart}
                            customInput={<CustomInputDate />}
                            portalId="root-portal"
                        />
                    </div>
                    <div>
                        <label htmlFor="dateTo" style={{ display: 'block' }}>To</label>
                        <DatePicker
                            selected={dateData.dateTo}
                            onChange={handleChangeEnd}
                            customInput={<CustomInputDate />}
                            portalId="root-portal"
                        />
                    </div>
                </div>

                {
                    !printSkeleton ? (
                        <ul className={styles.gridTable}>
                            <li className={styles.tableTitle}>Date</li >
                            <li className={styles.tableTitle}>Amount</li>
                            <li className={styles.tableTitle}>Detail</li>
                            {
                                data.map((data, index) => (
                                    <Item data={data} key={index} />
                                ))
                            }
                        </ul >
                    ) : (
                        !haveInvoices ? (
                            <Advice />
                        ) : (

                            <>
                                <ul className={styles.gridTable} >
                                    <li className={styles.tableTitle}>Date</li >
                                    <li className={styles.tableTitle}>Amount</li>
                                    <li className={styles.tableTitle}>Detail</li>
                                    <li><SkeletonBlock rows={1} /></li>
                                    <li><SkeletonBlock rows={1} /></li>
                                    <li><SkeletonBlock rows={1} /></li>
                                    <li><SkeletonBlock rows={1} /></li>
                                    <li><SkeletonBlock rows={1} /></li>
                                    <li><SkeletonBlock rows={1} /></li>
                                    <li><SkeletonBlock rows={1} /></li>
                                    <li><SkeletonBlock rows={1} /></li>
                                    <li><SkeletonBlock rows={1} /></li>
                                </ul >
                            </>
                        )

                    )
                }
            </div >

            <UnauthorizedModal httpStatusCode={serviceHttpStatusCode} />
        </>
    )
}

export default Invoices;


