import React, { PropsWithChildren, ReactElement } from "react";
import SkeletonBlock from "../../../../components/shared/SkeletonBlock";
import { IBalanceProps } from "../interfaces";
import styles from "./Balance.module.scss";

const Balance = (props: PropsWithChildren<IBalanceProps>): ReactElement => {
    const { balance , activeModal } = props;

    return (
        <div className={styles.balanceWrapper}>
            { (balance === 0 || balance) && <>
                <h3> Balance Due </h3>
                <h2> ${(balance >= 0) ? balance : 0 } </h2>
                <button className={`${(balance > 0) ? '' : styles.disable }`} onClick={()=>activeModal(true)}>Pay Now</button>
                </>
            }
            {
               !balance && balance != 0 && <>
               <h3> Balance Due </h3>
               <SkeletonBlock rows={2}/>
               </> 
            }
            
        </div>
    );
};

export default Balance;
