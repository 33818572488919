import { navigate } from "gatsby-link";
import React, { PropsWithChildren, ReactElement } from "react";
import { isWindow } from "../../../../shared/helpers";
import MembersModal from "../MembersModal";
import styles from './UnauthorizedModal.module.scss';

interface IUnauthorizedModalProps {
    httpStatusCode: number
}

const handleUnathorized = () => {
    if(isWindow)
        localStorage.removeItem('jwt-token');

    navigate("/members/login");
}

const UnauthorizedModal = (props: PropsWithChildren<IUnauthorizedModalProps>): ReactElement => {

    return (
        <>
            {props.httpStatusCode === 401 && <MembersModal title="Unauthorized" handleClose={handleUnathorized}>
                <h3 className={styles.unauthorizedTitle}>Your session has expired</h3>
                <span className={styles.unauthorizedSpan}>Please log in again</span>
                <div className={styles.btns}>
                    <button onClick={handleUnathorized}>Ok</button>
                </div>
            </MembersModal>}
        </>
    )
}

export default UnauthorizedModal;