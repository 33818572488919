import React from "react";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import { creditCardIcon } from "../../../../shared/icons";
import OrderDescription from "../OrderDescription";
import TotalOrder from "../TotalOrder";
import styles from "./ReviewOrder.module.scss";

const ReviewOrder = ({ membership, stepsSnapshot }) => {
    const billingInfo = stepsSnapshot[1];
    const cardName = billingInfo && billingInfo["card_name"]?.value;
    const cardNumber = billingInfo && billingInfo["card_number"]?.value;
    const cardExpiration = billingInfo && billingInfo["card_expiration"]?.value;

    const last4Characters = cardNumber?.substr(-4);
    const maskingCharacters = cardNumber?.substr(0, cardNumber.length - 4).replace(/\d/g, '*');
    const maskCreditCardNumber = maskingCharacters + last4Characters;

    const isDesktop = useWindowWidth(576);


    return (
        <>
            <OrderDescription membership={membership} />

            <div className={styles.paymentContainer}>
                <h4 className={styles.paymentTitle}>{ !isDesktop && <span> {creditCardIcon} </span> }Payment</h4>
                {!isDesktop && cardName && <p className={styles.nameContainer}>{cardName}</p>}
                <div className={styles.cardInfoContainer}>
                    {isDesktop && creditCardIcon}
                    {isDesktop && cardName && <p>{cardName}</p>}
                    {cardNumber && <p>{maskCreditCardNumber}</p>}
                    {cardExpiration && <p> Exp. {cardExpiration}</p>}
                </div>
            </div>

            <TotalOrder dueToday={membership.dueToday} />
        </>
    );
};

export default ReviewOrder;
