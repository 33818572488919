import React, {
    ChangeEvent,
    useRef,
    ReactElement,
    useState,
    createContext,
} from "react";

import { ValidationService } from "../../services/ValidationService";
import AuthService from "../../services/AuthService";
import { useDependency } from "../../contexts/DependencyContext";

import Form from "./components/Form";
import StepNavigationBar from "./components/StepNavigationBar";
import OrderDescription from "./components/OrderDescription";
import Modal from "../../components/shared/Modal";

import { steps as allSteps } from "./constants/steps";

import styles from "./AccountCreatePage.module.scss";
import _, { isEmpty } from "lodash";
import PrismicService from "../../services/PrismicService";
import DashboardApiService from "../../services/DashboardApiService";
import Spinner from "../../components/shared/Spinner";
import Helmet from "../../components/Helmet";
import { getSeoKeywords } from "../../shared/helpers";
import { PassThrough } from "stream";

const ErrorComponent = ({ errors }) => {
    return (
        <div className={styles.errorComponent}>
            <h1>Something went wrong!</h1>
            <ul>
                {errors.map((e, idx) => (
                    <li key={idx}>{e}</li>
                ))}
            </ul>
        </div>
    );
};

export const getErrorMessage = (
    name: string,
    validationType: string,
    metadata?: any
): string => {
    if (metadata?.customError) return metadata.customError;

    let errorMessage = "";

    switch (validationType) {
        case "text":
            errorMessage = "- Input can only contain letters";
            break;
        case "email":
            errorMessage = "- The email format is incorrect";
            break;
        case "tel":
            errorMessage = "- The telephone format is incorrect";
            break;
        case "required":
            errorMessage = "- This field is required";
            break;
        case "maxLength":
            errorMessage = `-The maximum number of characters allowed is ${metadata}`;
            break;
        case "equalsTo":
            errorMessage = `- The ${name} doesn't match`;
            break;
        case "creditCard":
            errorMessage = "- Wrong credit card number";
            break;
        case "remote":
            errorMessage = `- The ${name} is taken`;
            break;
        case "minDate":
            errorMessage = "- Date cannot be older than today";
            break;
        case "number":
            errorMessage = "- Input can only contain numbers";
            break;
        case "recaptchaToken":
            errorMessage = "- Recaptcha verification error"
            break;
    }

    return errorMessage;
};

export const AccountCreateContext = createContext({
    validate: (e: any, callback: (valid: boolean) => void) => { },
    prefillValues: (reuseData: any) => { },
    handleActions: (element: any, value: any) => { },
    setFormData: (formData: any) => { }
});

const AccountCreatePage = (props: any): ReactElement => {
    const membership = props.location.state?.membership;
    const location = props.location.state?.selectedLocation;
    const steps = !membership
        ? _.cloneDeep(allSteps.slice(0, 2))
        : _.cloneDeep(allSteps);

    const [skeleton, setSkeleton] = useState(true);
    const currentStep = useRef<number>(0);
    const [agreement, setAgreement] = useState<object>({});
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        0: { ...steps[0].formElements },
    });
    const [formErrors, setFormErrors] = useState({});
    const [termsAndConditions, setTermsAndConditions] = useState({} as any);
    const [isLoading, setIsLoading] = useState(false);
    const [prefillTrigger, setPrefillTrigger] = useState({
        name: "",
        value: null
    });
    const [recaptchaError, setRecaptchaError] = useState<boolean>(false);

    const token = useRef<string>("");
    const finalStep = useRef(false);
    const modalComponent = useRef(null);

    const validationService = useDependency(ValidationService);

    React.useEffect(() => {
        async function getTermsAndConditions() {
            const service = await PrismicService.getInstance();
            const tAndC = await service.getDocuments(
                "new_member_terms_and_conditions"
            );

            setTermsAndConditions(tAndC.results[0].data);
        }

        if (membership){ getAgreementData()}else{window.location.pathname = "/join-us"};
        
        getTermsAndConditions();
    }, []);

    const getAgreementData = async () => {
        const agreementDataParams = {
            id: membership?.id,
            groupId: membership?.groupId,
            itemId: membership?.itemId,
            businessUnitCode: location?.businessUnitCode,
            startDate: new Date(),
        };

        const agreement = await DashboardApiService.getAgreementData(
            agreementDataParams
        );

        token.current = agreement.token;
        setAgreement(agreement);
        setSkeleton(false);
    };

    const remoteValidation = async (element: any, callback: (valid: boolean) => void) => {
        const isValid = await validationService.setValidation(
            element.value,
            "remote",
            element.validations["remote"],
            element.name
        );

        callback(isValid)

        if (!isValid) {
            setFormErrors({
                ...formErrors,
                [element.name]: [getErrorMessage(element.name, "remote", element.validations["remote"])],
            });
        } else {
            const formCopy = { ...formErrors };

            if (formCopy[element.name]) {
                delete formCopy[element.name];

                setFormErrors(formCopy);
            }
        }
    };

    const getFinalResource = () => {

        let memberData: any = {};
        for (let key in formData[0]) {
            memberData[key] = {};
            memberData[key].value = formData[0][key].value;
            memberData[key].label = formData[0][key].label;
            memberData[key].type = formData[0][key].type;
        }

        let billingData: any = {};
        for (let key in formData[1]) {
            billingData[key] = {};
            billingData[key].value = formData[1][key].value;
            billingData[key].label = formData[1][key].label;
            billingData[key].type = formData[1][key].type;
        }

        //delete spaces for billing card number
        billingData.card_number.value = billingData.card_number.value.replace(/\s/g, '')

        const finalResource = _.cloneDeep({
            member: memberData,
            billing: billingData,
            membership: {
                agreement: {
                    id: membership?.id,
                    groupId: membership?.groupId,
                    itemId: membership?.itemId,
                    locationId: location?.businessUnitCode,
                },
            },
            dataToken: token.current,
            termsAndConditions: formData[2].terms_and_conditions.value,
        });

        delete finalResource.billing.personal_info;

        return finalResource;
    };

    const validate = async (element: any, callback: (valid: boolean) => void) => {
        const errors = [];
        const { name, validations, value, validationsToShow } = formData[currentStep.current][
            element.name
        ];
        const errorKeys = [];

        if (validations) {
            const validationsList = validationService.sortValidationList(validations);
            for (const validationKey in validationsList) {
                const validationValue = validationsList[validationKey];
                let isValid = false;

                if (validationKey === "remote") {
                    if (!errors.length) {
                        isValid = await validationService.setValidation(
                            value,
                            validationKey,
                            validationValue,
                            name
                        );

                        if (!isValid) {
                            const errMessage = getErrorMessage(
                                name,
                                validationKey,
                                validationValue
                            );

                            errorKeys.push(validationKey);
                            errors.push(errMessage);
                        }
                    }
                } else {
                    isValid = await validationService.setValidation(
                        value,
                        validationKey,
                        validationValue,
                        name
                    );

                    if (!isValid) {
                        const errMessage = getErrorMessage(
                            name,
                            validationKey,
                            validationValue
                        );

                        errorKeys.push(validationKey);
                        errors.push(errMessage);
                    }
                }
                if (validationsToShow && errors.length === validationsToShow) break;
            }

            setFormErrors({
                ...formErrors,
                [name]: errors
            });
        }

        callback(errors.length === 0, errorKeys);
    };

    const onStepClick = async (direction: string, jumpTo?: number) => {
        const errors = {};
        let index;

        if (typeof jumpTo === "undefined") {
            index = direction === "next" ? currentStep.current + 1 : currentStep.current - 1;
        } else {
            index = jumpTo;
        }

        if (index !== steps.length){
            window.scrollTo({top: 0});
        }
        
        if (index < currentStep.current) {
            finalStep.current = false;
            currentStep.current = index;
            setFormErrors({});
            
        } else {
            for (const elementKey in formData[currentStep.current]) {
                const { name, validations, value, abstract, skip } = formData[currentStep.current][
                    elementKey
                ];

                if (abstract || skip) continue;

                if (validations) {
                    for (let validationKey in validations) {
                        const validationValue = validations[validationKey];
                        let isValid = false;

                        isValid = await validationService.setValidation(
                            value,
                            validationKey,
                            validationValue,
                            name
                        );

                        if (!isValid) {
                            if (!errors[name]) {
                                errors[name] = [];
                            }

                            errors[name].push(
                                getErrorMessage(
                                    name,
                                    validationKey,
                                    validationValue
                                )
                            );
                        }
                    }
                }
            }

            let recaptchaResponse = null;
            if (finalStep.current) {
                recaptchaResponse = grecaptcha.getResponse();
                setRecaptchaError(!recaptchaResponse);
            }

            if (isEmpty(errors)) {
                if (steps[index]) {
                    if (index + 1 === steps.length) finalStep.current = true;
                    else {
                        finalStep.current = false;
                    }

                    currentStep.current = index;

                    setFormData({
                        ...formData,
                        [index]: !formData[index] ? steps[index].formElements : formData[index]
                    });
                } else if (index === steps.length && recaptchaResponse) {
                    const resourceObj = getFinalResource();
                    setFormErrors({});

                    setIsLoading(true);
                    const result = await AuthService.register(resourceObj, recaptchaResponse);
                    setIsLoading(false);

                    if (result.data.token) {
                        token.current = result.data.token;
                    }

                    if (result.data.success) {
                        const response = result.data.response;

                        props.navigate("/members/create/success", {
                            state: {
                                membership: agreement,
                                response,
                                billing: resourceObj.billing,
                            },
                        });
                    } else {
                        let errors = [];
                        console.log("Results");
                        console.log(result);
                        if (result.data.errors) {
                            const newFormErrors = {};

                            result.data.errors.forEach(
                                e => (newFormErrors[e.field] = e.messages)
                            );

                            finalStep.current = false;
                            currentStep.current = getLowestErrorStep(newFormErrors);

                            setFormErrors({
                                ...formErrors,
                                ...newFormErrors,
                            });

                            errors = result.data.errors
                                .map(e => e.messages)
                                .flat();
                        } else {
                            errors.push(result?.data?.response?.message);
                        }

                        handleModal(<ErrorComponent errors={errors} />);
                    }
                }
            } else {
                setFormErrors(errors);
            }
        }
    };

    const prefillValues = ({ targetStep, fields }: any, checked: boolean, triggerName: string): void => 
    {
        if (prefillTrigger.name === triggerName && prefillTrigger.value === checked) return;

        setPrefillTrigger({name: triggerName, value: checked});
        const newFields = {};
        let applyChanges = false;

        for (const fieldIndex in fields.from) {
            const tField = fields.from[fieldIndex];
            const cField = fields.to[fieldIndex];

            const targetField = formData[targetStep][tField];
            const currentField = { ...formData[currentStep.current][cField] };
            applyChanges = applyChanges || 
                checked
                    ? targetField.value !== currentField.value
                    : currentField.value  !== "";

            currentField.value = checked ? targetField.value : "";

            newFields[cField] = currentField;
        };

        if (applyChanges) {
            setFormData({
                ...formData,
                [currentStep.current]: {
                    ...formData[currentStep.current],
                    ...newFields
                }
            });
        }
    };

    const handleActions = (element, value) => {
        const { reset, skipValidation } = element.actions;
        const targetElemName = reset || skipValidation;

        if (skipValidation) {}
            // setFormData({
            //     ...formData,
            //     [currentStep.current]: {
            //         ...formData[currentStep.current],
            //         [targetElemName]: {
            //             ...formData[currentStep.current][targetElemName],
            //             skip: value
            //         }
            //     }
            // });

        //     if (value) {
        //         setFormErrors({
        //             ...formErrors,
        //             [targetElemName]: []
        //         });
        //     }
        // } else if (reset) {
        //     setFormData({
        //         ...formData,
        //         [currentStep.current]: {
        //             ...formData[currentStep.current],
        //             [element.name]: {
        //                 ...formData[currentStep.current][targetElemName],
        //                 skip: false
        //             }
        //         }
        //     });

        //     if (value) {
        //         setFormErrors({
        //             ...formErrors,
        //             [targetElemName]: []
        //         });
        //     }
        // }
    };

    const handleOnChange = (event: ChangeEvent, element: any): void => {
        const target = event.currentTarget as any;
        const formElement = formData[currentStep.current][target.name];

        const setData = () => {
            setFormData({
                ...formData,
                [currentStep.current]: {
                    ...formData[currentStep.current],
                    [target.name]: {
                        ...formElement,
                        value: target.value,
                    },
                },
            });
        }

        //Reset validations on change.
        if (target?.name && !isEmpty(target?.name)){
            setFormErrors({
                ...formErrors,
                [target.name]: []
            });
        }

        if (element?.actions) {
            if (element.actions.reset) {
                const resetTargetName = element.actions.reset;
                const resetTarget = formData[currentStep.current][target.name];

                setFormData({
                    ...formData,
                    [currentStep.current]: {
                        ...formData[currentStep.current],
                        [target.name]: {
                            ...formElement,
                            skip: false,
                            value: target.value,
                        },
                        [resetTargetName]: {
                            ...resetTarget,
                            value: false
                        }
                    },
                });
                
            }

            if (element.actions.skipValidation) {
                const skipTargetName = element.actions.skipValidation;
                const skipTarget = formData[currentStep.current][skipTargetName];

                setFormData({
                    ...formData,
                    [currentStep.current]: {
                        ...formData[currentStep.current],
                        [skipTargetName]: {
                            ...skipTarget,
                            skip: target.value
                        }
                    }
                });
                
                if (target.value) {
                    setFormErrors({
                        ...formErrors,
                        [skipTargetName]: []
                    });
                }
            }
        } else {
            setData();
        }
    };

    const handleOnChangeSelect = (element, option): void => {
        setFormData({
            ...formData,
            [currentStep.current]: {
                ...formData[currentStep.current],
                [element.name]: {
                    ...formData[currentStep.current][element.name],
                    value: option.value,
                },
            },
        });

        setFormErrors({
            ...formErrors,
            [element.name]: []
        });
    };

    const handleModal = (_modalComponent: any) => {
        modalComponent.current = _modalComponent;

        setShowModal(true);
    };

    const getLowestErrorStep = (errors: object): number | null => {
        let step = 0;

        for (const stepIndex in formData) {
            const index = Number(stepIndex);
            const stepElements = formData[index];

            for (const field in errors) {
                if (stepElements[field]) {
                    return index;
                }
            }
        }

        return step;
    };

    return (
        <>
            <Helmet 
                title="Create account" 
                description="Create your account in our gyms"
            />
            <div
                className={`${styles.mainContainer} ${!membership ? styles.noMembership : ""
                    } ${finalStep.current ? styles.isFinalStep : ""}`}
            >
                {membership && !finalStep.current && (
                    <OrderDescription
                        membership={agreement}
                        buildSkeleton={skeleton}
                    />
                )}

                <div className={styles.formWrapper}>
                    <StepNavigationBar
                        steps={steps}
                        currentStep={currentStep.current}
                        {...{ onStepClick }}
                    />

                    <AccountCreateContext.Provider
                        value={{
                            validate,
                            prefillValues,
                            termsAndConditions,
                            handleActions
                        }}
                    >
                        <Form
                            steps={steps}
                            {...{ formData }}
                            {...{ onStepClick }}
                            currentStep={currentStep.current}
                            membership={agreement}
                            errors={formErrors}
                            handleInputChange={handleOnChange}
                            handleSelectChange={handleOnChangeSelect}
                            recaptchaError={recaptchaError}
                        />
                    </AccountCreateContext.Provider>
                </div>

                {showModal && (
                    <Modal handleClose={() => setShowModal(false)} styles={{ borderRadius: 3 }} errorCreateAccount={true}>
                        <div>{modalComponent.current}</div>
                    </Modal>
                )}

                {isLoading && <Spinner loadingMsg={"We're processing your transaction, please wait."} />}

            </div>
        </>
    );
};

export default AccountCreatePage;