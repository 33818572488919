import React, { useState, useEffect } from 'react';
import styles from './PaymentMethod.module.scss';
import WebAccountService from '../../../services/WebAccountService';
import CustomCheckbox from '../../../components/shared/CustomCheckbox';
import SkeletonBlock from '../../../components/shared/SkeletonBlock';
import CvvInput from '../../../components/shared/CVVInput';
import ExpirationInput from '../../../components/shared/ExpirationInput';
import CardInput from '../../../components/shared/CardInput';
import { IGetPaymentMethod, ISavePaymentMethodRequest } from '../../../services/interfaces/IPaymentMethod';
import { edit, errorFilterIcon } from '../../../shared/icons';
import CustomCheckboxValue from '../../../components/shared/CustomCheckBoxValue';
import CustomSelect from '../../../components/shared/CustomSelect';
import ZipCode from '../../../components/shared/ZipCode';
import { stateOptions } from '../../Account_create_page/constants/states';
import MembersModal from '../Shared/MembersModal';
import { IControlValidation } from '../../../components/shared/General/interfaces';
import UnauthorizedModal from '../Shared/UnauthorizedModal';
import Helmet from '../../../components/Helmet';

const PaymentMethod = () => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const [showSkeleton, setShowSkeleton] = useState<boolean>(true);
    // const [sameAddress, setSameAddress] = useState<boolean>(true);
    const [currentData, setCurrentData] = useState<IGetPaymentMethod>();
    const [formData, setFormData] = useState<ISavePaymentMethodRequest>({
        creditCard: { cardNumber: "", cvv: "", expirationYear: "", expirationMonth: "", nameOnCard: "" },
        address: { address1: "", address2: "", state: "", city: "", zipCode: "" },
        homeAddress: true
    });
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    const [controlsValidations, setControlsValidations] = useState<IControlValidation[]>([{ name: "cardNumber", valid: false }, { name: "cvv", valid: false }, { name: "expiration", valid: false }, { name: "zipCode", valid: true }]);
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [serviceHttpStatusCode, setServiceHttpStatusCode] = useState<number>(0);

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleCancel = () => {
        setEditMode(false);
    };

    const handleModalClose = async () => {
        setShowModal(false);
    }

    const handleSave = async (e: any) => {
        // -- Open spinner
        setIsLoad(true);
        const response = await WebAccountService.savePaymentMethod(formData as ISavePaymentMethodRequest);
        // -- Close spinner
        setIsLoad(false);
        if (response.success) {
            setModalTitle("Success");
            setModalMessage("Your payment has been updated");
        }
        else {
            if (response.httpStatusCode !== 401) {
                setModalTitle("Error");
                setModalMessage(response.message);
            }
            else {
                setServiceHttpStatusCode(response.httpStatusCode);
            }
        }
        setShowModal(true);
    }

    useEffect(() => {
        const handleData = async () => {
            try {
                const response = await WebAccountService.getPaymentMethod();
                setShowSkeleton(false);

                if (!response.success) {
                    if (response.httpStatusCode === 401) {
                        setServiceHttpStatusCode(response.httpStatusCode);
                    }
                }
                else if (response.response) {
                    setCurrentData({
                        cardNumber: response.response.cardNumber,
                        expirationMonth: response.response.expirationMonth,
                        expirationYear: response.response.expirationYear,
                        nameOnCard: response.response.nameOnCard,
                    })
                }
            } catch (error) {
                console.log(error)
            }
        }

        handleData();
    }, []);

    const sameAddressChange = (e: React.ChangeEvent<Element>): void => {
        setFormData({ ...formData, homeAddress: !formData.homeAddress });
    }

    const handleSelectChange = (name: string, value: string): void => {
        const currentOption = stateOptions.find(x => x.label === value);
        setFormData({
            ...formData, address: {
                ...formData?.address,
                state: currentOption?.value
            }
        } as ISavePaymentMethodRequest)
    };

    const handleChange = (name: string, value: string, valid: boolean) => {
        if (name === "address1" || name === "address2" || name === "zipCode" || name === "city" || name === "state") {
            setFormData({ ...formData, address: { ...formData?.address, [name]: value } } as ISavePaymentMethodRequest);
        }
        else if (name === "cardNumber" || name === "cvv" || name === "nameOnCard") {
            setFormData({ ...formData, creditCard: { ...formData?.creditCard, [name]: value } } as ISavePaymentMethodRequest);
        }
        else if (name === "expiration" && value.length === 7) {
            let separeteArray = value.split('/')
            setFormData({ ...formData, creditCard: { ...formData?.creditCard, expirationMonth: separeteArray[0], expirationYear: separeteArray[1] } } as ISavePaymentMethodRequest);
        }
        else {
            setFormData({ ...formData, [name]: value } as ISavePaymentMethodRequest);
        }

        let validation = controlsValidations.find(x => x.name === name);
        if (validation) {
            validation.valid = valid;
            setControlsValidations([...controlsValidations.filter(x => x.name !== name), validation as IControlValidation]);
        }
    }

    const validateControls = (): boolean => {
        for (const item of controlsValidations) {
            // -- Don't evaluate
            if (item.name === "zipCode" && formData.homeAddress) {
                continue;
            }
            if (!item.valid)
                return false;
        }
        return true;
    }

    useEffect(() => {
        if (!validateControls() ||
            (!formData.homeAddress && (!formData?.address?.address1 || !formData?.address?.city || !formData?.address?.state))
            || !formData?.creditCard?.nameOnCard) {
            setSaveDisabled(true);
            return;
        }

        setSaveDisabled(false);
    }, [formData, controlsValidations]);

    return (
        <>
        <Helmet title={'Payment Method'}/>
            <div className={styles.payment}>
                <div className={styles.container}>
                    <h2 className={styles.title}>Payment Method</h2>
                    <div>
                        {!editMode && <button className={styles.button} onClick={handleEdit}>{edit}Edit</button>}
                        {editMode && <button className={`${styles.btnMargin} ${styles.button}`} onClick={handleCancel}>Cancel</button>}
                        {editMode && <button className={`${styles.btnMargin} ${styles.buttonInverse}`} onClick={handleSave}>Save</button>}
                    </div>
                </div>
                <ul className={styles.content}>
                    <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                        <h4>Card Number</h4>
                        {!editMode &&
                            <>
                                {!showSkeleton && <p className={styles.currentData}>{currentData?.cardNumber}</p>}
                                {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                            </>
                        }
                        {
                            editMode &&
                            <CardInput setValue={handleChange} />
                        }
                    </li>
                    <div className={styles.stateRow}>
                        <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                            <h4>CVV</h4>
                            {!editMode &&
                                <>
                                    {!showSkeleton && <p className={styles.currentData}>...</p>}
                                    {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                                </>
                            }
                            {
                                editMode &&
                                <CvvInput
                                    cardNumber={formData?.creditCard?.cardNumber as string}
                                    setValue={handleChange} />
                            }
                        </li>
                        <li className={` ${!showSkeleton ? styles.skeletonFlex : ''}`}>
                            {!editMode &&
                                <div className={styles.inputMargin}>
                                    <h4>Expiration Date</h4>
                                    {!showSkeleton && <p className={styles.currentData}>{currentData?.expirationMonth}/{currentData?.expirationYear}</p>}
                                    {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                                </div>
                            }
                            {
                                editMode &&
                                <div className={styles.inputEditMargin}>
                                    <h4>Expiration Date</h4>
                                    <ExpirationInput setValue={handleChange} />
                                </div>
                            }
                        </li>
                    </div>
                    <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                        <h4>Name On Card</h4>
                        {!editMode &&
                            <>
                                {!showSkeleton &&
                                    <p className={styles.currentData}>{currentData?.nameOnCard}</p>
                                }
                                {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                            </>
                        }
                        {
                            editMode &&
                            <input type="text" name='nameOnCard' value={formData?.creditCard?.nameOnCard} onChange={e => handleChange(e.target.name, e.target.value, e.target.value ? true : false)} placeholder={currentData?.nameOnCard} />
                        }
                    </li>
                    <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                        <h4>Billing Address</h4>
                    </li>
                    <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                        {!editMode &&
                            <>
                                {!showSkeleton && formData != null &&
                                    <CustomCheckboxValue name="sameAddress" label={"Same as Home Address"} disabled gray checked={formData.homeAddress} handleChange={sameAddressChange} />}
                                {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                            </>
                        }
                        {
                            editMode &&
                            <CustomCheckboxValue name="sameAddress" label={"Same as Home Address"} disabled={false} checked={formData.homeAddress} handleChange={sameAddressChange} />
                        }
                    </li>

                    {!formData.homeAddress && editMode &&
                        <>
                            <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                                {!editMode &&
                                    <>
                                        {!showSkeleton &&
                                            <p className={styles.currentData}>{formData?.address?.address1}</p>
                                        }
                                        {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                                    </>
                                }
                                {
                                    editMode &&
                                    <input type="text" name='address1' value={formData?.address?.address1} placeholder="Address 1" onChange={e => handleChange(e.target.name, e.target.value, e.target.value ? true : false)} />
                                }
                            </li>
                            <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                                {!editMode &&
                                    <>
                                        {!showSkeleton && <p className={styles.currentData}>{formData?.address?.address2}</p>}
                                        {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                                    </>
                                }
                                {
                                    editMode &&
                                    <input type="text" name='address2' value={formData?.address?.address2} placeholder="Address 2" onChange={e => handleChange(e.target.name, e.target.value, e.target.value ? true : false)} />
                                }
                            </li>
                            <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                                {!editMode &&
                                    <>
                                        {!showSkeleton && <p className={styles.currentData}>{formData?.address?.city}</p>}
                                        {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                                    </>
                                }
                                {
                                    editMode &&
                                    <input type="text" name='city' value={formData?.address?.city} placeholder="City" onChange={e => handleChange(e.target.name, e.target.value, e.target.value ? true : false)} />
                                }
                            </li>
                            <div className={styles.stateRow}>
                                <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                                    {!editMode &&
                                        <>
                                            {!showSkeleton && <p className={styles.currentData}>{stateOptions.find(x => x.value === formData?.address?.state)?.label}</p>}
                                            {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                                        </>
                                    }
                                    {
                                        editMode &&
                                        <div>
                                            <CustomSelect
                                                name={"state"}
                                                options={stateOptions}
                                                placeholder={"State"}
                                                handleChange={handleSelectChange}
                                                selectedValue={formData?.address?.state}
                                                isWhite={true} />
                                        </div>
                                    }
                                </li>
                                <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                                    {!editMode &&
                                        <>
                                            {!showSkeleton && <p className={styles.currentData}>{formData?.address?.zipCode}</p>}
                                            {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                                        </>
                                    }
                                    {
                                        editMode &&
                                        <div className={styles.inputEditMargin}>
                                            <ZipCode defaultValue={formData?.address?.zipCode as string} setValue={handleChange} placeholder="ZIP CODE" />
                                        </div>
                                    }
                                </li>
                            </div>
                        </>
                    }
                </ul>
            </div >
            {isLoad && <MembersModal handleClose={() => setIsLoad(false)} title="loading" loading={true} />}
            {showModal && <MembersModal handleClose={handleModalClose} title={modalTitle}>{modalMessage}</MembersModal>}
            <UnauthorizedModal httpStatusCode={serviceHttpStatusCode} />
        </>
    )
};
export default PaymentMethod;