import React, { useState, useEffect, PropsWithChildren, ReactElement } from 'react';
import SkeletonBlock from '../../../components/shared/SkeletonBlock'
import styles from './UpcomingClasses.module.scss';
import WebAccountService from '../../../services/WebAccountService';
import Item from './Item/Item';
import { Advice } from './Advice';
import { Link } from "gatsby";
import MembersModal from "../Shared/MembersModal";
import UnauthorizedModal from '../Shared/UnauthorizedModal';
import Helmet from '../../../components/Helmet';
import { IReferMessage } from '../Dashboard/Interfaces';


interface Date {
    dateFrom: string;
    dateTo: string;
}

const UpcomingClasses = (props: PropsWithChildren<any>): ReactElement => {

    const [data, setData] = useState([]);
    const [printSkeleton, setPrintSkeleton] = useState(false);
    const [haveUpcomings, setHaveUpcomings] = useState(true);
    const [message, setMessage] = useState<IReferMessage | boolean>(false);
    const [serviceHttpStatusCode, setServiceHttpStatusCode] = useState<number>(0);

    function tidyUp(a: any, b: any) {
        if (a.start < b.start) {
            return -1;
        }
        if (a.start > b.start) {
            return 1;
        }
        return 0;
    }

    const handleData = async () => {
        try {
            const response = await WebAccountService.upcomingClasses();

            if (response.success && response.response.length >= 1) {
                setData(response.response.sort(tidyUp));
                setPrintSkeleton(false);
            } else {
                if (response.httpStatusCode !== 401) {
                    setHaveUpcomings(false);
                }
                else {
                    setServiceHttpStatusCode(response.httpStatusCode);
                }
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        setPrintSkeleton(true);
        handleData();
        setHaveUpcomings(true);
    }, [message]);

    const businessUnitCode = localStorage.getItem("businessUnitCode")
    const scheduleLink = businessUnitCode ? `/scheduler?businessUnitCode=${businessUnitCode}` : "/scheduler";

    return (
        <>
        <Helmet title={'Upcoming Classes'}/>
            <div className={styles.upcomingClasses}>
                <div className={styles.container}>
                    <h2 className={styles.title}>Upcoming Classes</h2>
                    <div>
                        <Link className={styles.button} to={scheduleLink}>Find a Class</ Link >
                    </div>
                </div>
                {
                    !printSkeleton ? (
                        <>
                            {
                                data.map((data, eventInstanceId) => (
                                    <Item data={data} key={eventInstanceId} setMessage={setMessage} />
                                ))
                            }
                        </>
                    ) : (
                        !haveUpcomings ? (
                            <Advice />
                        ) : (
                            <>
                                <div className={styles.class}>
                                    <div className={styles.img}>
                                        <SkeletonBlock rows={1} styles={{ height: '200px', width: '300px', margin: 0 }} />
                                    </div>
                                    <div className={styles.date}>
                                        <SkeletonBlock rows={1} />
                                        <SkeletonBlock rows={1} />
                                    </div>
                                    <div className={styles.info}>
                                        <SkeletonBlock rows={1} /><SkeletonBlock rows={1} />
                                        <SkeletonBlock rows={1} /><SkeletonBlock rows={1} />

                                    </div>
                                </div>
                                <div className={styles.class}>
                                    <div className={styles.img}>
                                        <SkeletonBlock rows={1} styles={{ height: '200px', width: '300px', margin: 0 }} />
                                    </div>
                                    <div className={styles.date}>
                                        <SkeletonBlock rows={1} />
                                        <SkeletonBlock rows={2} />
                                    </div>
                                    <div className={styles.info}>
                                        <SkeletonBlock rows={1} /><SkeletonBlock rows={1} />
                                        <SkeletonBlock rows={1} /><SkeletonBlock rows={1} />

                                    </div>
                                </div>
                            </>
                        )
                    )
                }
            </div >
            {
                message &&
                <MembersModal title={"Upcoming CLasses"} handleClose={() => setMessage(false)} loading={typeof message === 'boolean' ? message : false}>
                    {
                        typeof message === 'object' &&
                        <p> {message.message}</p>
                    }
                </MembersModal>
            }
            <UnauthorizedModal httpStatusCode={serviceHttpStatusCode} />
        </>
    )
}


export default UpcomingClasses;