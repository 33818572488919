import { Link } from "gatsby";
import React from "react";
import { errorFilterIcon } from "../../../../shared/icons";
import styles from "./Alert.module.scss";

function Alert() {
    return (
        <div className={styles.alert}>
            <span className={styles.icon}>{errorFilterIcon}</span>
            <h3>
                We’re sorry, an error has occurred with your payment
                method.
            </h3>
            <Link
                className={styles.goFix}
                to={"/members/payment"}
            >
                Fix it
            </Link>
        </div>
    );
}

export default Alert;
