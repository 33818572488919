import React, { PropsWithChildren, ReactElement } from "react";
import SkeletonBlock from "../../../../components/shared/SkeletonBlock";
import { IMembershipProps } from "../interfaces";
import styles from "./MembershipData.module.scss";

const MembershipData =(props: PropsWithChildren<IMembershipProps>): ReactElement => {

    const { membership } = props;


    const statusClass = (code :number ) =>{
        switch (code) {
            case 1:
                return styles.active;
            case 2:
                return styles.freeze;
            case 3:
                return styles.inactive;
            default:
                return styles.skeleton;
                break;
        }
    }

    return (
        <>
            <div className={`${styles.dataWrapper} ${membership && statusClass(membership.status)} ${membership ? "" : styles.skeleton }`}>
        {membership && 
                <>
                <div>
                    <h3 className={styles.title}>
                        Type of membership
                    </h3>
                    <p className={styles.typeMembership}>{membership.name}</p>
                    <p className={styles.typeMembership}>{membership.priceLabel}</p>
                    <p className={styles.typeMembership}>Monthly Fee ${membership.monthlyFee}</p>
                </div>
                <div>
                    <h3 className={styles.title}>
                        Features
                    </h3>
                    {membership.features.length != 0  ? 
                    membership.features.map((feature :string, i:number)=><p key={i+'f'}>{feature}</p>) 
                    :
                    <p> No features to display </p>
                }
                </div>
                <div>
                    <h3 className={styles.title}>
                        My Additionals
                    </h3>
                    {membership.additionals.length != 0 ? 
                        membership.additionals.map((add :string, i:number)=><p key={i+'ad'}>{add}</p>)
                        :
                        <p>You don't have additionals on your membership</p>
                }
                </div>
                </>
            }
            {!membership &&
            <>
                <div>
                    <h3 className={styles.title}>
                        Type of membership
                    </h3>
                    <SkeletonBlock rows={3} styles={{width : '50%',  align: 'flex-start'}}/>
                </div>
                <div>
                    <h4>
                        Features
                    </h4>
                    <SkeletonBlock rows={3} styles={{width : '70%',  align: 'flex-start'}}/>
                </div>
                <div>
                    <h4>
                        My Additionals
                    </h4>
                    <SkeletonBlock rows={3} styles={{width : '50%',  align: 'flex-start'}}/>
                </div>
            </>
            }
            </div>

        </>
    );
};

export default MembershipData;
