import React from "react"
import { navigate } from "gatsby"
import { isWindow } from "../../shared/helpers"

const isLoggedIn = () =>{
  return localStorage.getItem('jwt-token')
}
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (isWindow && !isLoggedIn() && location.pathname !== `/members/login`) {
    navigate("/members/login")
    return null
  }
  return <Component {...rest} />
}
export default PrivateRoute