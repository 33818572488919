import React, {
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from "react";
import { genericCardIcon } from "../../../shared/icons";
import { CardType } from "../CardInput/Interfaces";
import ErrorMsg from "../ErrorMsg";
import styles from "./ZipCode.module.scss";
import { ZipCodeFormatter } from "./zipcodeinfo";

interface IZipCodeProps {
    defaultValue: string;
    setValue(name: string, value: string, valid: boolean): void;
    placeholder?: string;
}

const ZipCode = (
    props: PropsWithChildren<IZipCodeProps>,
): ReactElement => {

    let formatter = new ZipCodeFormatter();
    const [error, setError] = useState<string>("");

    const matchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let formattedValue = formatter.format(e.target.value);
        let msg = formatter.validate(e.target.value);
        props.setValue(e.target.name, formattedValue, msg === "");
    };

    const blurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let msg = formatter.validate(e.target.value);
        // -- Validate value
        setError(msg);

        props.setValue(e.target.name, e.target.value, msg === "");
    };

    return (
        <div>
            <div className={styles.zipCodeWrapper}>
                <input
                    type="text"
                    onChange={e => matchHandler(e)}
                    className={styles.zipCode}
                    onBlur={e => blurHandler(e)}
                    id='zipCode'
                    name='zipCode'
                    value={props.defaultValue}
                    placeholder={props.placeholder}
                />
                {error != "" && <ErrorMsg errors={[error]} />}
            </div>
        </div>
    );
};

export default ZipCode;
