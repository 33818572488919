import { stateOptions } from "./states";
import { genderOptions } from "./gender";
import { TermsAndConditions } from "../components/TermsAndConditions";
import moment from "moment";

const dateNow = new Date();
const dateNowTimeless = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate())
const firstDayTimeless = new Date(dateNow.getFullYear(), dateNow.getMonth());

export const steps = [
    {
        index: 0,
        name: "",
        actionButtonName: "Create account",
        formElements: {
            "name": {
                label: "First Name:*",
                name: "name",
                type: "text",
                inline: true,
                validations: {
                    maxLength: 255,
                    required: true
                }
            },
            "last_name": {
                label: "Last name:*",
                name: "last_name",
                type: "text",
                inline: true,
                validations: {
                    maxLength: 255,
                    required: true
                }
            },
            "email": {
                label: "Email:*",
                name: "email",
                type: "email",
                actions: {
                    reset: "force_email"
                },
                validations: {
                    maxLength: 255,
                    email: true,
                    required: true,
                    remote: {
                        val: "validate/email",
                        customError: "- We couldn't verify your email address, please check your input",
                        onError: {
                            reveal: [
                                "force_email"
                            ]
                        }
                    }
                }
            },
            "force_email": {
                name: "force_email",
                type: "checkbox",
                label: "This is ok",
                abstract: true,
                options: [
                    {
                        label: "I confirm that this email is correct",
                        value: 0
                    }
                ],
                actions: {
                    skipValidation: "email"
                }
            },
            "phone": {
                label: "Phone:*",
                name: "phone",
                type: "text",
                placeholder: "(xxx) xxx-xxxx",
                formatter: "phoneNum",
                validations: {
                    required: true,
                    tel: true,
                    remote: {
                        val: "validate/phone",
                        customError: "- We couldn't validate your phone number, please check your input",
                    }
                }
            },
            "birth_date": {
                label: "Date of birth:*",
                name: "birth_date",
                type: "date",
                value: null,
                validations: {
                    required: {
                        val: true,
                        customError: "- You must be 18 or older to join online"
                    },
                    date: true,
                    maxDate: {
                        val: new Date(dateNowTimeless.setFullYear(dateNow.getFullYear() - 18)),
                        customError: "- You must be 18 or older to join online"
                    },
                    between: {
                        val: [
                            new Date(dateNowTimeless.setFullYear(dateNow.getFullYear() - 14)),
                            new Date(dateNowTimeless.setFullYear(dateNow.getFullYear() - 18))
                        ],
                        customError: "- If you are between 14 and 18 years old you must go to the club to register"
                    }
                }
            },
            "gender": {
                label: "Gender:*",
                name: "gender",
                type: "select",
                value: "",
                options: genderOptions,
                validations: {
                    required: true
                }
            },
            "address_1": {
                label: "Address 1:*",
                name: "address_1",
                type: "text",
                validations: {
                    required: true
                }
            },
            "address_2": {
                label: "Address 2:",
                name: "address_2",
                type: "text"
            },
            "city": {
                label: "City:*",
                name: "city",
                type: "text",
                validations: {
                    required: true,
                    text: true
                }
            },
            "state": {
                label: "State:*",
                name: "state",
                type: "select",
                inline: true,
                value: "",
                options: stateOptions,
                validations: {
                    required: true
                  }
            },
            "zip_code": {
                label: "Zip Code:*",
                name: "zip_code",
                type: "text",
                inline: true,
                validations: {
                    required: true,
                    number: true,
                    maxLength: 5,
                    minLength: {
                        val: 5,
                        customError: "- Zip Code must have at least 5 digits"
                    }
                  }
            }
        }
    },
    {
        index: 1,
        name: "Billing Details",
        formElements: {
            "card_name": {
                label: "Card holder name:*",
                name: "card_name",
                type: "text",
                validations: {
                    required: true,
                    cardHolder: {
                        val: true,
                        customError: "- Invalid Card Holder name"
                    }
                }
            },
            "card_number": {
                label: "Credit card number:*",
                name: "card_number",
                type: "text",
                formatter: "cardNum",
                validations: {
                    required: true,
                    creditCard: true,
                }
            },
            "card_expiration": {
                label: "Expiration (MM/YYYY):*",
                name: "card_expiration",
                type: "text",
                formatter: "cardExp",
                validations: {
                    required: true,
                    minLength: {
                        val: 7,
                        customError: "- Wrong date format"
                    },
                    validExpDate: moment(firstDayTimeless)
                }
            },
            "personal_info": {
                label: "",
                name: "personal_info",
                type: "checkbox",
                value: true,
                reuse: {
                    step: 1,
                    targetStep: 0,
                    fields: {
                        from: ["address_1", "address_2", "state", "zip_code", "city"],
                        to: ["billing_address_1", "billing_address_2", "billing_state", "billing_zip_code", "billing_city"]
                    }
                },
                options: [
                    {
                        label: "Use home address as billing",
                        value: 0
                    }
                ]
            },
            "billing_address_1": {
                label: "Address 1:*",
                name: "billing_address_1",
                toggle: "personal_info",
                type: "text",
                validations: {
                    required: true
                  }
            },
            "billing_address_2": {
                label: "Address 2:",
                name: "billing_address_2",
                toggle: "personal_info",
                type: "text",
            },
            "billing_city": {
                label: "City:*",
                name: "billing_city",
                toggle: "personal_info",
                type: "text",
                validations: {
                    required: true,
                    text: true
                }
            },
            "billing_state": {
                label: "State/Province:*",
                name: "billing_state",
                toggle: "personal_info",
                type: "select",
                value: "",
                options: stateOptions,
                inline: true,
                validations: {
                    required: true
                  }
            },
            "billing_zip_code": {
                label: "Zip Code:*",
                name: "billing_zip_code",
                toggle: "personal_info",
                type: "text",
                inline: true,
                validations: {
                    required: true,
                    maxLength: 5,
                    number: true,
                    minLength: {
                        val: 5,
                        customError: "- Zip Code must have at least 5 digits"
                    },
                  }
            }
        }
    },
    {
        index: 2,
        name: "Review your order",
        actionButtonName: "Submit order",
        review: true,
        formElements:
            {
                "terms_and_conditions": {
                    label: "Yes, I have read and accepted the +Terms and Conditions+",
                    component: TermsAndConditions,
                    propName: "termsAndConditions",
                    name: "terms_and_conditions",
                    type: "checkbox",
                    centerContent: true,
                    value: false,
                    disabled: true,
                    validationsToShow: 1,
                    validations: {
                        required: {
                            customError: "- You must read and accept the terms and conditions",
                            order: 1
                        },
                        disabled: {
                            customError: "- You must scroll through the Terms and Conditions in order to check the accept box.",
                            order: 0
                        }
                    },
                    options: [
                        {
                            name: "Yes, I have read and accepted the +Terms and Conditions+",
                            value: 1
                        }
                    ]
                }
            }
    }
];

