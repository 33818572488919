import React from 'react';
import styles from './SpinnerOnValidationLoad.module.scss'

function SpinnerOnValidationLoad() {
    return (
        <div className={styles.spinnerContainer}>
            <div className={styles.loader}></div>
        </div>
    )
}

export default SpinnerOnValidationLoad
