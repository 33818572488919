import React from "react";
import styles from "./StepNavigationBar.module.scss";

const StepNavigationBar = ({ steps, currentStep, onStepClick }) => {
    const getStepClassName = (stepIndex) => {
        const stepIsCurrent = currentStep === stepIndex;

        return `${styles.stepDot} ${stepIsCurrent ? styles.current : ''}`;
    };

    return (
        <nav>
            <ol className={styles.stepDotContainer}>
                {
                   steps.map((step, index) => <li key={index} className={getStepClassName(index)} onClick={() => onStepClick(null, index)}>{index + 1}</li>)
                }
            </ol>
        </nav>
    );
};

export default StepNavigationBar;