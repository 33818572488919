import React from "react";
import { PropsWithChildren, ReactElement } from "react";
import MembersModal from "../MembersModal";
import { INotificationModalProps } from "./interface";
import styles from "./NotificationModal.module.scss";

const NotificationModal = (props: PropsWithChildren<INotificationModalProps>): ReactElement => {
    return (
    <MembersModal title={""} handleClose={() => props.handleClose()} notification={true}>
        <div className={styles.notification}>
            <h3>{props.title}</h3>
            <span>{props.span}</span>
            <p>{props.message}</p>

        </div>
    </MembersModal>)
}

export default NotificationModal;