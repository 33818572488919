import React from "react";
import styles from "./TotalOrder.module.scss";

const TotalOrder = ({ dueToday }: any) => {
    return (
        <div className={styles.totalOrder}>
            <p>Final Order Total</p> <span>{dueToday}</span>
        </div>
    );
};

export default TotalOrder;
