import React from 'react';
import { dateHandler } from '../../../../shared/helpers';

const Item = ({ data }) => {
    const { date, time, location } = data;
    const currentDate = new Date(date);
    const currentProps = dateHandler(currentDate);
    const { dayName, numberDate, monthName } = currentProps;
    let cutDate = numberDate.split("/")[1];

    return (
        <>
            <li>{dayName.short}, {monthName.short} {cutDate}</li>
            <li>{time}</li>
            <li>{location}</li>
        </>
    )
}

export default Item;