import { isNull, isNumber } from "lodash";
import { useDependency } from "../../../contexts/DependencyContext";
import BaseApiService from "../../../services/BaseApiService";
import { IAsyncFormatter } from "../General/interfaces";

export class PhoneNumFormatter implements IAsyncFormatter {

    current: string;
    prev: string;
    erasing: boolean;
    maxLength: number;

    constructor() {
        this.current = "";
        this.prev = "";
        this.erasing = false;
        this.maxLength = 14;
    }

    async validate(val: string): Promise<string> {
        let res = await BaseApiService.post("validate/phone", { phone: val });
        if (res.data.success) {
            return "";
        }
        else {
            return "We couldn't validate your phone number, please check your input";
        }
    }

    setValue(val: string, character: string = ""): void {
        this.prev = this.current;
        this.current = val + character;

        if (this.prev.length > this.current.length) {
            this.erasing = true;
        } else {
            this.erasing = false;
            const lastIsNumber = !isNaN(Number(val[val.length - 1]));

            if (!lastIsNumber && val.length != 4 && val.length != 5 && val.length != 10) {
                this.current = this.prev;
            }
        }

        if (this.current.length === this.maxLength) {
            this.prev = this.current;
        }
    }

    format(val: string): string {
        // -- Limit the value
        if(val.length > this.maxLength){
            return val.slice(0, this.maxLength);
        }
        // -- Get only numbers
        let onlyNumbers = val.replace(/\D/g,'');

        if (onlyNumbers.length === 3) {
            onlyNumbers = '(' + onlyNumbers.substring(0, 3) + ') '
        }
        else if (onlyNumbers.length < 4 && onlyNumbers.length != 0) {
            onlyNumbers = '(' + onlyNumbers;
        }
        else if (onlyNumbers.length >= 4 && onlyNumbers.length < 6) {
            onlyNumbers = '(' + onlyNumbers.substring(0, 3) + ') ' + onlyNumbers.substring(3, 6);
        }
        else if (onlyNumbers.length === 6) {
            onlyNumbers = '(' + onlyNumbers.substring(0, 3) + ') ' + onlyNumbers.substring(3, 6) + '-';
        }
        else if (onlyNumbers.length > 6) {
            onlyNumbers = '(' + onlyNumbers.substring(0, 3) + ') ' + onlyNumbers.substring(3, 6) + '-' + onlyNumbers.substring(6, 10);
        }

        return onlyNumbers;
    }
}