import React, { PropsWithChildren, ReactElement, useLayoutEffect } from "react";
import SkeletonBlock from "../../../../components/shared/SkeletonBlock";
import { dateHandler } from "../../../../shared/helpers";
import { IprofileProps } from "../Interfaces";
import styles from "./Profile.module.scss";


const Profile = (props: PropsWithChildren<IprofileProps>): ReactElement => {
    const { profileData, activeModal } = props;

    let nextBillDate;
    let profileImage = '/assets/image/blank-user.png';

    if(profileData && profileData.image)
          profileImage = `data:image/jpeg;base64,${ profileData.image}`;

    if(profileData && profileData.nextBill)
        nextBillDate = dateHandler(new Date(profileData.nextBill.paymentDueDate));

        const statusClass = (code :number ) =>{
            switch (code) {
                case 1:
                    return styles.active;
                case 2:
                    return styles.freeze;
                case 3:
                    return styles.inactive;
                default:
                    break;
            }
        }
    
        const statusName = (code :number ) =>{
            switch (code) {
                case 1:
                    return "ACTIVE"
                case 2:
                    return "FREEZE"
                case 3:
                    return "INACTIVE"
                default:
                    break;
            }
        }

    return (
        <div className={styles.profile}>
            {profileData && (
                <>
                    <div className={styles.pictureWrapper}>
                        <div className={styles.picture}>
                            <img
                                src={profileImage}
                                alt={`profile-photo-${profileData.name}`}
                            />
                        </div>
                    </div>
                    <div className={styles.name}>
                        <h3>{profileData.name}</h3>
                        <h4>Member ID #{profileData.roleid}</h4>
                    </div>
                    <div className={styles.membership}>
                        <h3>
                            Membership
                            <span className={`${statusClass(profileData.membership.status)}`}>
                                {statusName(profileData.membership.status)}
                            </span>
                        </h3>
                        <h4>{profileData.membership.name}</h4>
                    </div>
                    <div className={styles.homeClub}>
                        <h4> Home Club </h4>
                        <p> {profileData.homeClub} </p>
                    </div>
                    <div className={styles.nextBill}>
                        <h4> Next Bill </h4>
                        <p> {profileData.nextBill.amount} - {nextBillDate?.monthName.short} {nextBillDate?.dayNumber} </p>
                    </div>
                    <div className={styles.balance}>
                        <h3> Balance Due </h3>
                        <h2> ${(profileData.balance >= 0) ? profileData.balance : 0 } </h2>
                        <button className={`${(profileData.balance > 0) ? '' : styles.disable }`} onClick={()=>activeModal(true)}>Pay Now</button>
                    </div>
                    <div className={styles.line}></div>
                </>
            )}
            {/* Show skeleton without data */}
            {!profileData && (
                <>
                    <div className={styles.pictureWrapper}>
                        <div className={styles.picture}>
                            <SkeletonBlock rows={1} styles={{ height : `200px`, margin : 0}}/>
                        </div>
                    </div>
                    <div className={styles.name}>
                        <SkeletonBlock rows={2} />
                    </div>
                    <div className={styles.membership}>
                        <SkeletonBlock rows={2} />
                    </div>
                    <div className={styles.homeClub}>
                        <SkeletonBlock rows={1} />
                    </div>
                    <div className={styles.nextBill}>
                        <SkeletonBlock rows={1} />
                    </div>
                    <div className={styles.balance}>
                        <SkeletonBlock rows={3} />
                    </div>
                    <div className={styles.line}></div>
                </>
            )}
        </div>
    );
}

export default Profile