import React, { ReactElement } from 'react'
import styles from './CreditCardActive.module.scss'
import { visaIcon, discoverIcon, mastercardIcon, genericCardIcon, amexIcon } from '../../../../../shared/icons'

interface cardTypeProps {
    type: number
}
function CreditCardActive(props: cardTypeProps): ReactElement {

    let icon = null;
    switch (props.type) {
        case 0:
            icon = amexIcon
            break;
        case 1:
            icon = mastercardIcon
            break;
        case 2:
            icon = visaIcon
            break;
        case 3:
            icon = discoverIcon
            break;

        default:
            icon = genericCardIcon
            break;
    }

    return (
        <div className={styles.creditIcon}>
            {icon}
        </div>
    )
}

export default CreditCardActive
