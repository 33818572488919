export const genderOptions = [
    {
        "label": "Choose One",
        "value": ""
    },
    {
        "label": "Male",
        "value": "Male"
    },
    {
        "label": "Female",
        "value": "Female"
    },
    {
        "label": "Not Specified",
        "value": "Not Specified"
    }
];