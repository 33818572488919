import api from "./DashboardApiService";

interface IUserInfo {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    password: string;
    repeatPassword: string;
};

const authenticatedApiRoutes = [
    "/auth/changePassword"
];

class AuthService {
    private static readonly baseUrl: string = "auth";

    public static async login(username: string, password: string): Promise<any> {
        const loginResponse = await api.post(this.baseUrl, { username, password });

        localStorage.setItem("jwt-token", loginResponse.data);

        api.setRequestHeaders("x-auth", loginResponse.data, authenticatedApiRoutes);

        return Promise.resolve(loginResponse.data);
    };

    public static async register(userInfo: any, recaptchaResponse: string): Promise<any> {
        try {
            let response = await api.post(`members/create`, {...userInfo, recaptchaResponse});
            return response;
        }
        catch (error:any) {
            return { success: false, message: "Unexpected error", httpStatusCode: error.response?.status, data: error.response.data }
        }
    };

    public static async changePassword(oldPassword: string, newPassword: string): Promise<any> {
        return await api.post(`${this.baseUrl}/changePassword`, { oldPassword, newPassword });
    };
}

export default AuthService;
