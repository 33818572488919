import React, { PropsWithChildren, ReactElement, ChangeEvent, useState, useEffect, Fragment } from "react";
import { checkboxIcon } from "../../../shared/icons";
import styles from "./CustomCheckBoxValue.module.scss";

interface CustomCheckboxValueProps {
    name: string;
    label: string;
    handleChange: (e: ChangeEvent) => void;
    checked?: boolean;
    disabled?: boolean;
    gray?: boolean;
}

const CustomCheckboxValue = (props: PropsWithChildren<CustomCheckboxValueProps>): ReactElement => {
    const handleChange = (e: ChangeEvent): void => {
        props.handleChange(e);
    }

    return (
        <div className={styles.customCheckbox}>
            <input
                type="checkbox"
                name={props.name}
                onChange={handleChange}
                disabled={props.disabled ? true : false}
            />
            <div className={`${!props.gray ? styles.svgContainerDefault : styles.svgContainerGray}`}>
                {props.checked ? checkboxIcon.checked : checkboxIcon.unchecked}
                <label htmlFor={props.name}>
                    {props.label}
                </label>
            </div>

        </div>
    );
};

export default CustomCheckboxValue;
