import { replace } from "lodash";
import { IFormatter } from "../General/interfaces";

export class ZipCodeFormatter implements IFormatter {
    setValue(val: string, character: string): void {
        throw new Error("Method not implemented.");
    }
    validate(val: string): string {
        if (val.length === 5)
            return ""

        return "Invalid Zip Code";
    }

    format(val: string): string {
        let replaced = val.replace(/[^0-9\.]+/g, '');
        if (replaced.length > 5) {
            replaced = replaced.slice(0, 5);
        }

        return replaced;
    }
}