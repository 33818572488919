import React, { useState, useEffect, PropsWithChildren, ReactElement } from 'react';
import styles from './ResetPassword.module.scss';
import WebAccountService from "../../../services/WebAccountService";
import Spinner from '../../../components/shared/Spinner';
import MembersModal from '../Shared/MembersModal';
import UnauthorizedModal from '../Shared/UnauthorizedModal';
import PrismicService from '../../../services/PrismicService';
import { IPrismicText } from '../../../components/Widgets/TwoSidesImageBanner/Interfaces/IData';
import SkeletonBlock from '../../../components/shared/SkeletonBlock';
import { fallBackTexts } from '../fallbackTexts';
import Helmet from '../../../components/Helmet';

const ResetPassword = (props: PropsWithChildren<any>): ReactElement => {
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [serviceHttpStatusCode, setServiceHttpStatusCode] = useState<number>(0);
    const [message, setMessage] = useState<IPrismicText[] | null >(null);


    const handleSubmit = async () => {
        setConfirm(false);
        // -- Open spinner
        setIsLoad(true);
        const response = await WebAccountService.resetPassword();
        // -- Close spinner
        setIsLoad(false);
        if (response.success) {
            setModalTitle("Success");
            setModalMessage("Email sent");
        }
        else {
            if (response.httpStatusCode !== 401) {
                setModalTitle("Error");
                setModalMessage(response.message);
            }
            else {
                setServiceHttpStatusCode(response.httpStatusCode);
            }
        }
        setShowModal(true);
    };

    const handleModalClose = async () => {
        setShowModal(false);
    }

    const getPrismicData = async () => {
        const prismicInstance = await PrismicService.getInstance();
        const dashboardContent = await prismicInstance.getDocuments(
            "dashboard_content",
        );
        return dashboardContent.results;
    };

    const handleData = async () =>{
        const prismicResetPass = await getPrismicData();
        try {
            setMessage(prismicResetPass[0].data.reset_password_info)
        } catch (error) {
            setMessage(fallBackTexts.resetPass)
        }
    }


    useEffect(() => {
        handleData();
    }, []);

    return (
        <>
        <Helmet title={'Reset your Password'}/>
            <div className={`${styles.resetPassword} print-block`}>
                <h2 className={styles.title}>Reset Password</h2>

                <ul className={styles.content}>
                    <li>
                    {!message ? 
                    <SkeletonBlock rows={3} /> :
                    <p>{message[0].text}</p>}
                    </li>
                </ul>

                <button className={styles.button} onClick={handleSubmit}>Reset password</button>
            </div >
            {isLoad && <MembersModal handleClose={() => setIsLoad(false)} title="loading" loading={true} />}
            {showModal && <MembersModal handleClose={handleModalClose} title={modalTitle}>{modalMessage}</MembersModal>}
            <UnauthorizedModal httpStatusCode={serviceHttpStatusCode} />
        </>
    )
}

export default ResetPassword;