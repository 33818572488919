import React, { useState, useEffect, PropsWithChildren, ReactElement } from 'react';
import CustomCheckbox from '../../../components/shared/CustomCheckbox';
import CustomCheckBoxValue from '../../../components/shared/CustomCheckBoxValue';
import CustomSelect from '../../../components/shared/CustomSelect';
import EmailInput from '../../../components/shared/EmailInput';
import { IControlValidation } from '../../../components/shared/General/interfaces';
import PhoneNumberInput from '../../../components/shared/PhoneNumberInput';
import SkeletonBlock from '../../../components/shared/SkeletonBlock';
import ZipCode from '../../../components/shared/ZipCode';
import { IGetAccountInfo } from '../../../services/interfaces/IGetAccountInfo';
import { ISaveAccountInfoRequest } from '../../../services/interfaces/ISaveAccountInfo';
import WebAccountService from '../../../services/WebAccountService';
import { stateOptions } from '../../Account_create_page/constants/states';
import MembersModal from '../Shared/MembersModal';
import UnauthorizedModal from '../Shared/UnauthorizedModal';
import styles from './MemberInfo.module.scss';
import { edit } from '../../../shared/icons';
import Helmet from '../../../components/Helmet';


const MemberInfo = (): ReactElement => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const [showSkeleton, setShowSkeleton] = useState<boolean>(true);
    const [currentData, setCurrentData] = useState<IGetAccountInfo>();
    const [formData, setFormData] = useState<ISaveAccountInfoRequest>();
    const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
    const [controlsValidations, setControlsValidations] = useState<IControlValidation[]>([{ name: "email", valid: true }, { name: "phone", valid: true }, { name: "zipCode", valid: true }]);
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [serviceHttpStatusCode, setServiceHttpStatusCode] = useState<number>(0);


    const handleEdit = () => {
        setEditMode(true);
    }

    const handleCancel = () => {
        setEditMode(false);
    }

    useEffect(() => {
        const loadInfo = async () => {
            let res = await WebAccountService.getAccountInfo();
            setCurrentData(res.response);
            setShowSkeleton(false);
            if (!res.success) {
                if (res.httpStatusCode === 401) {
                    setServiceHttpStatusCode(res.httpStatusCode);
                }
            }
            else if (res.response) {
                setFormData({
                    address: res.response.address,
                    email: res.response.email,
                    notifyNews: res.response.notifyNews,
                    phone: res.response.phone,
                    validEmail: false
                });
            }
        }

        loadInfo();
    }, [])

    const handleChange = (name: string, value: string, valid: boolean) => {
        if (name === "address1" || name === "address2" || name === "zipCode" || name === "city" || name === "state") {
            setFormData({ ...formData, address: { ...formData?.address, [name]: value } } as ISaveAccountInfoRequest);
        }
        else {
            let validEmail = name === "email" ? valid : formData?.validEmail;
            setFormData({ ...formData, [name]: value, validEmail } as ISaveAccountInfoRequest);
        }

        let validation = controlsValidations.find(x => x.name === name);
        if (validation) {
            validation.valid = valid;
            setControlsValidations([...controlsValidations.filter(x => x.name !== name), validation as IControlValidation]);
        }
    }

    const notifyNewsChange = (e: React.ChangeEvent<Element>): void => {
        setFormData({ ...formData, notifyNews: !formData?.notifyNews } as ISaveAccountInfoRequest);
    }

    const handleSelectChange = (name: string, value: string): void => {
        const currentOption = stateOptions.find(x => x.label === value);
        setFormData({
            ...formData, address: {
                ...formData?.address,
                state: currentOption?.value
            }
        } as ISaveAccountInfoRequest)
    };

    const handleSave = async (e: any) => {
        // -- Open spinner
        setIsLoad(true);
        const response = await WebAccountService.saveAccountInfo(formData as ISaveAccountInfoRequest);
        // -- Close spinner
        setIsLoad(false);
        if (response.success) {
            setModalTitle("Success");
            setModalMessage("Your information has been updated");
            setEditMode(false);
        }
        else {
            if (response.httpStatusCode !== 401) {
                setModalTitle("Error");
                setModalMessage(response.message);
            }
            else {
                setServiceHttpStatusCode(response.httpStatusCode);
            }
        }
        setShowModal(true);
    }

    const handleModalClose = async () => {
        setShowModal(false);
    }

    useEffect(() => {
        if (!validateControls() ||
            !formData?.address?.address1 || !formData?.address?.city || !formData?.address?.state || !formData?.address?.zipCode) {
            setSaveDisabled(true);
            return;
        }

        setSaveDisabled(false);
    }, [formData, controlsValidations]);


    const validateControls = (): boolean => {
        for (const item of controlsValidations) {
            if (!item.valid)
                return false;
        }
        return true;
    }


    return (
        <>
            <Helmet title={'Member Information'} />
            <div className={styles.memberInfo}>
                <div className={styles.container}>
                    <h2 className={styles.title}>Member Information</h2>
                    <div>
                        {!editMode && <button className={styles.button} onClick={handleEdit}>{edit} Edit</button>}
                        {editMode && <button className={`${styles.btnMargin} ${styles.button}`} onClick={handleCancel}>Cancel</button>}
                        {editMode && <button onClick={handleSave} className={`${styles.btnMargin} ${styles.buttonInverse} ${saveDisabled ? styles.disable : ''}`} disabled={saveDisabled}>Save</button>}
                    </div>
                </div>

                <ul className={styles.content}>
                    <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                        <h4>
                            Profile Picture
                            {!showSkeleton && <img src={currentData?.image ? `data:image/jpeg;base64,${currentData.image}` : '/assets/image/blank-user.png'} alt={`profile-photo-${currentData?.userName}`} />}
                            {showSkeleton && <SkeletonBlock rows={1} styles={{ height: `160px`, width: `160px`, margin: 0 }} />}
                            {
                                editMode &&
                                <label>Please, to change your photo go to the Gym.</label>
                            }
                        </h4>
                    </li>
                    <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                        <h4>Username</h4>
                        {!editMode &&
                            <>
                                {!showSkeleton && <p className={styles.currentData}>{currentData?.userName}</p>}
                                {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                            </>
                        }
                        {
                            editMode &&
                            <input className={styles.readOnly} type="text" name='userName' readOnly value={currentData?.userName} />
                        }
                    </li>
                    <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                        <h4>Name</h4>
                        {!editMode &&
                            <>
                                {!showSkeleton && <p className={styles.currentData}>{currentData?.name}</p>}
                                {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                            </>
                        }
                        {
                            editMode &&
                            <input className={styles.readOnly} type="text" name='name' readOnly value={currentData?.name} />
                        }
                    </li>
                    <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                        <h4>Phone</h4>
                        {!editMode &&
                            <>
                                {!showSkeleton && <p className={styles.currentData}>{formData?.phone}</p>}
                                {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                            </>
                        }
                        {
                            editMode &&
                            <PhoneNumberInput setValue={handleChange} defaultValue={formData?.phone} />
                        }
                    </li>
                    <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                        <h4>Email</h4>
                        {!editMode &&
                            <>
                                {!showSkeleton && <p className={styles.currentData}>{formData?.email}</p>}
                                {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                            </>
                        }
                        {
                            editMode &&
                            <EmailInput setValue={handleChange} defaultValue={formData?.email} />
                            // <input type="text" name='email' value={formData?.email} placeholder="example@email.com"/>
                        }
                    </li>
                    <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                        {!editMode &&
                            <>
                                {!showSkeleton &&
                                    <CustomCheckBoxValue name={"notifyNews"} label={"Yes, send me the latest and greatest on new classes, offerings, and locations."} handleChange={notifyNewsChange} checked={formData?.notifyNews} disabled gray />}
                                {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                            </>
                        }
                        {
                            editMode &&
                            <CustomCheckBoxValue name={"notifyNews"} label={"Yes, send me the latest and greatest on new classes, offerings, and locations."} handleChange={notifyNewsChange} checked={formData?.notifyNews} disabled={false} />
                        }
                    </li>
                    <li className={styles.subTitle}>
                        <h3>Home Address</h3>
                    </li>
                    <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                        <h4>Address 1</h4>
                        {!editMode &&
                            <>
                                {!showSkeleton &&
                                    <p className={styles.currentData}>{formData?.address?.address1}</p>
                                }
                                {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                            </>
                        }
                        {
                            editMode &&
                            <input type="text" name='address1' value={formData?.address?.address1} placeholder="4540 Donald ross rd." onChange={e => handleChange(e.target.name, e.target.value, e.target.value ? true : false)} />
                        }
                    </li>
                    <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                        <h4>Address 2</h4>
                        {!editMode &&
                            <>
                                {!showSkeleton && <p className={styles.currentData}>{formData?.address?.address2}</p>}
                                {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                            </>
                        }
                        {
                            editMode &&
                            <input type="text" name='address2' value={formData?.address?.address2} placeholder="Suite 2530" onChange={e => handleChange(e.target.name, e.target.value, e.target.value ? true : false)} />
                        }
                    </li>
                    <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                        <h4>City</h4>
                        {!editMode &&
                            <>
                                {!showSkeleton && <p className={styles.currentData}>{formData?.address?.city}</p>}
                                {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                            </>
                        }
                        {
                            editMode &&
                            <input type="text" name='city' value={formData?.address?.city} placeholder="Miami" onChange={e => handleChange(e.target.name, e.target.value, e.target.value ? true : false)} />
                        }
                    </li>
                    <div className={styles.stateRow}>
                        <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>

                            {!editMode &&
                                <>
                                    <h4>State</h4>
                                    {!showSkeleton && <p className={styles.currentData}>{stateOptions.find(x => x.value === formData?.address?.state)?.label}</p>}
                                    {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                                </>
                            }
                            {
                                editMode &&
                                <div>
                                    <h4>State</h4>
                                    <CustomSelect
                                        name={"state"}
                                        options={stateOptions}
                                        placeholder={"Choose One"}
                                        handleChange={handleSelectChange}
                                        selectedValue={formData?.address?.state}
                                        isWhite={true} />
                                </div>
                            }
                        </li>
                        <li className={` ${showSkeleton ? styles.skeletonFlex : ''}`}>
                            {!editMode &&
                                <>
                                    <h4 className={` ${showSkeleton ? styles.inputMargin : ''}`}>Zip Code</h4>
                                    {!showSkeleton && <p className={styles.currentData}>{formData?.address?.zipCode}</p>}
                                    {showSkeleton && <SkeletonBlock rows={1} styles={{ margin: 0 }} />}
                                </>
                            }
                            {
                                editMode &&
                                <div className={styles.inputMargin}>
                                    <h4>Zip Code</h4>
                                    <ZipCode defaultValue={formData?.address?.zipCode as string} setValue={handleChange} />
                                </div>
                            }
                        </li>
                    </div>
                </ul>

            </div >
            {isLoad && <MembersModal handleClose={() => setIsLoad(false)} title="loading" loading={true} />}
            {showModal && <MembersModal handleClose={handleModalClose} title={modalTitle}>{modalMessage}</MembersModal>}
            <UnauthorizedModal httpStatusCode={serviceHttpStatusCode} />
        </>
    )
}

export default MemberInfo;