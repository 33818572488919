import { first } from "lodash";
import React from "react";
import styles from "./OrderDescription.module.scss";

const OrderDescription = (props: any) => {
    const { membership, buildSkeleton, additionalClass } = props
    return (
        <div className={`${styles.orderDescriptionContainer} orderDescriptionAux`}>
            <div className={styles.stickyBox}>
                {buildSkeleton ? (<div className={styles.skeleton}>
                    <h4 className={styles.locationDetail}>

                    </h4>
                    <div className={styles.monthlyFeeAmountWrapper}>
                        <p className={styles.monthlyFeeAmount}></p>
                        <p className={styles.firstLine}></p>
                        <p></p>
                    </div>
                    <p className={styles.duesInformation}>
                    </p>

                    <div className={styles.paymentDetail}>
                        <div className={styles.displayFlex}>
                            <div className={styles.taxesDetail}>
                                <p></p>
                                <p className={styles.large}></p>
                                <p></p>
                            </div>
                            <div className={styles.dueToday}>
                                <p></p>
                                <p className={styles.dueAmount}></p>
                            </div>
                        </div>
                        <div className={`${styles.displayFlex} ${styles.column}`}>
                            <p className={styles.firstLine}></p>
                            <p></p>
                        </div>
                    </div>
                </div>) :
                    (<>
                        <h4 className={styles.locationDetail}>
                            {membership.name}
                        </h4>
                        <div className={styles.monthlyFeeAmountWrapper}>
                            <p className={styles.monthlyFeeAmount}>Monthly Fee: {membership.monthlyDues}</p>
                            {(membership.adjustments == null || membership.adjustments == '') &&
                                <p>Your first month´s dues are prorated based on the number of days remaining.</p>
                            }
                        </div>
                        <div className={styles.paymentDetail}>
                            <div className={styles.displayFlex}>
                                <div className={styles.taxesDetail}>
                                    <p>Joining Fee: {membership.joiningFee}</p>
                                    {(membership.adjustments == null || membership.adjustments == '') &&
                                        <p>Pro-rated dues: {membership.proratedDues}</p>
                                    }
                                    <p>Sales Tax: {membership.salesTax}</p>
                                    {membership.adjustments != null && membership.adjustments != '' &&
                                        <>
                                            <p>Discount: {membership.adjustments}</p>
                                            <p>{membership.adjstDescription}</p>
                                        </>
                                    }
                                </div>
                                <div className={`${styles.dueToday } ${additionalClass}`}>
                                    <h3>Due Today</h3>
                                    <p className={`${styles.dueAmount}`}>{membership.dueToday}</p>
                                </div>
                            </div>
                            <div className={`${styles.displayFlex} ${styles.noMarginAndBgMd}`}>
                                <p className={styles.annualFeeDescription}>Your first annual fee in the amount of {membership.annualFee} will be billed on the 15th of next month and each year on your membership anniversary date. </p>
                            </div>
                        </div>
                    </>)}
            </div>
        </div>
    );
};

export default OrderDescription;
