import React, { PropsWithChildren, useState } from "react";
import WebAccountService from "../../../../services/WebAccountService";
import { referFriendIcon } from "../../../../shared/icons";
import { IReferProps } from "../Interfaces";
import styles from "./ReferFriend.module.scss";
import PhoneNumberInput from '../../../../components/shared/PhoneNumberInput';
import EmailInput from '../../../../components/shared/EmailInput';
import { IReferFriend } from "../../../../services/interfaces/IReferFriend";
import SkeletonBlock from "../../../../components/shared/SkeletonBlock";

const ReferFriend = (props: PropsWithChildren<IReferProps>) => {
    const { setMessage, texts } = props
    const [formReferValues, setFormReferValues] = useState<IReferFriend>({
        name: undefined as any,
        email: undefined as any,
        phone: undefined as any,
        validEmail : false
    });

    const handleChange = (name: string, value: string, valid: boolean) => {
        if (name === "email") {
            let validEmail = name === "email" ? valid : formReferValues?.validEmail;
            setFormReferValues({ ...formReferValues, [name]: value, validEmail } as IReferFriend);
        }
        else {
            setFormReferValues({ ...formReferValues, [name]: value });
        };
    };

    const handleChangeName = (event : React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormReferValues({ ...formReferValues, name: value });
    };

    const handleReferFriend = async (e: React.FormEvent) => {
        e.preventDefault()

        setMessage(true)
        const refer = await WebAccountService.referFriend({
            name: formReferValues.name,
            email: formReferValues.email,
            phone: formReferValues.phone,
            validEmail : formReferValues.validEmail
        })

        if (refer.success) {
            setMessage({
                success: true,
                message: 'The e-mail was sent successfully'
            });
            setFormReferValues({
                name: "",
                email: "",
                phone: "",
                validEmail : false
            })
        } else {
            setMessage({
                success: false,
                message: refer.message
            })
        }
    }

    return (
        <div className={`${styles.referWrapper } ${(!texts) && styles.skeleton}`}>
            {(!texts) ? 
            <>
            <div className={styles.topSection}>
                <div className={styles.icon}>
                    <SkeletonBlock rows={1} styles={{ height: '100px', width : '100px' }}/>
                </div>
                <div className={`${styles.refer} ${styles.skeleton}`}>
                        <SkeletonBlock rows={1} styles={{ height: '30px', width : '50%' }}/>
                        <SkeletonBlock rows={3} />
                    </div> 
                </div>
            <div className={styles.forms}>
                <form>
                    <ul>
                        <li>
                        <SkeletonBlock rows={1} styles={{ height: '30px'}}/>
                        </li>
                        <li>
                        <SkeletonBlock rows={1} styles={{ height: '30px'}}/>
                        </li>
                        <li>
                        <SkeletonBlock rows={1} styles={{ height: '30px'}}/>
                        </li>
                    </ul>
                </form>
            </div>
            </>
            : 
            <>
            <div className={styles.topSection}>
            <div className={styles.icon}>
                    {referFriendIcon}
            </div>
            <div className={styles.refer}>
                <h3> {texts.refer_title[0].text} </h3>
                <small>{texts.refer_desc[0].text}</small>
            </div>
            </div>
            <div className={styles.forms}>
                <form onSubmit={handleReferFriend}>
                    <ul>
                        <li>
                            <input
                                onChange={handleChangeName}
                                placeholder="Full name"
                                name="fullName"
                                value={formReferValues.name}
                                type="text"
                                id="name"
                                className={styles.input}
                            />
                            <label htmlFor="name">
                                Full name
                            </label>

                        </li>
                        <li>
                            <EmailInput setValue={handleChange} className={styles.input} defaultValue={formReferValues?.email} placeholder={'yourfriend@mail.com'}/>
                            <label htmlFor="email">
                                Email
                            </label>
                        </li>
                        <li>
                            <PhoneNumberInput setValue={handleChange} className={styles.input} defaultValue={formReferValues?.phone} />
                            <label htmlFor="phone">
                                Phone
                            </label>
                        </li>
                        <li>
                            <div className={`${styles.btnContainer} ${(formReferValues.name && formReferValues.email && formReferValues.phone && formReferValues.validEmail ) ? '' : styles.disabled }`}>
                                <button
                                    className={styles.button}
                                >
                                    Send
                                </button>
                            </div>
                        </li>
                    </ul>
                </form>
            </div>
            </>
            }


        </div>
    );
}

export default ReferFriend
