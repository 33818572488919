import React, {
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from "react";
import styles from "./MembershipInformation.module.scss";
import WebAccountService from "../../../services/WebAccountService";
import PayDueModal from "../Shared/PayDueModal";
import MembersModal from "../Shared/MembersModal";
import { IBillData, IMembershipData, IMembershipProps, IMessage } from "./interfaces";
import MembershipData from "./MembershipData";
import HomeClub from "./HomeClub";
import BillData from "./BillData";
import Balance from "./Balance";
import { IMembership } from "../../../services/interfaces/IMembership";
import UnauthorizedModal from "../Shared/UnauthorizedModal";
import Helmet from "../../../components/Helmet";

const MembershipInformation = (
    props: PropsWithChildren<any>,
): ReactElement => {
    const [membership, setMembership] = useState<IMembership | null>(null);
    const [membershipData, setMembershipData] = useState<IMembershipData | null>(null);
    const [billData, setBillData] = useState<IBillData | null>(null);
    const [activeModal, setModalActive] = useState<Boolean>(false);
    const [paymentLoad, setPaymentLoad] = useState<IMessage | boolean>(false);
    const [serviceHttpStatusCode, setServiceHttpStatusCode] = useState<number>(0);

    const handleData = async () => {
        const res = await WebAccountService.membership();
        if (res.success) {
            const data: IMembership = res.response;
            setMembership(data);
        } else {
            //log out and go register and login
            setServiceHttpStatusCode(res.httpStatusCode);
        }
    };

    const paymentModalClose = () => {
        setModalActive(!activeModal);
        let isWindow = typeof window !== "undefined" ? window : null;
        if (
            typeof paymentLoad === "object" &&
            paymentLoad.success &&
            isWindow
        ) {
            window.location.reload();
        } else {
            setPaymentLoad(false);
        }
    };

    useEffect(() => {
        handleData();
    }, []);

    useEffect(() => {
        if (membership) {
            setMembershipData({
                features: membership.features,
                additionals: membership.additionals,
                priceLabel: membership.priceLabel,
                name: membership.name,
                monthlyFee: membership.monthlyFee,
                status: membership.status
            });

            setBillData({
                ...membership.nextBill,
                ...membership.creditCard
            })

            //setUpcoming(dashboardLoad.upcomingClass);
        }
    }, [membership]);

    return (
        <>
        <Helmet title={'Membership Information'}/>
            <h2 className={styles.title}>Membership information</h2>
            <div className={styles.dashboard}>
                <MembershipData membership={membershipData} />
                <Balance balance={membership ? membership.balance : membership} activeModal={setModalActive} />
                <BillData bill={billData} />
                <HomeClub location={membership ? membership.location : membership} />
            </div>
            {activeModal && (
                <MembersModal
                    title={typeof paymentLoad === "object" ? "" : "Credit Card"}
                    handleClose={() => paymentModalClose()}
                    loading={typeof paymentLoad === "boolean" ? paymentLoad : false}
                >
                    {typeof paymentLoad === "object" && (
                        <>
                            {paymentLoad.success ? (
                                <div className={styles.succesPayment}>
                                    <h3>Success!</h3>
                                    <span>Payment complete.</span>
                                </div>
                            ) : (
                                <h3 className={styles.wrongPayment}>
                                    Something went wrong
                                </h3>
                            )}
                            <p> {paymentLoad.message} </p>
                        </>
                    )}
                    {(typeof paymentLoad === 'boolean') && <PayDueModal amount={membership ? membership.balance : null} paymentLoad={setPaymentLoad} />}
                </MembersModal>
            )}
            <UnauthorizedModal httpStatusCode={serviceHttpStatusCode} />
        </>
    );
};

export default MembershipInformation;
