import React, { PropsWithChildren, ReactElement, useState } from "react";
import {
    amexIcon,
    discoverIcon,
    genericCardIcon,
    mastercardIcon,
    visaIcon,
} from "../../../shared/icons";
import { CardNumFormatter, cardsInfo } from "./cardsInfo";
import styles from './CardInput.module.scss'
import { CardType } from "./Interfaces";
import ErrorMsg from "../ErrorMsg";

interface ICardInputProps {
    setValue(name: string, value: string, valid: boolean): void;
}

const CardInput = (props: PropsWithChildren<ICardInputProps>): ReactElement => {
    const [creditCardType, setCreditCardType] = useState<number>(CardType.UNKNOWN);
    const formatter = new CardNumFormatter();
    const [error, setError] = useState<string>("");

    const matchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {

        let formattedValue = formatter.format(e.target.value)
        let maxLenght = formatter.maxLength
        setCreditCardType(formatter.active != null ? formatter.active : CardType.UNKNOWN)

        e.target.value = formattedValue;
        e.target.maxLength = maxLenght;
    };

    const blurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let validationError = formatter.validate(e.target.value);
        // -- Validate value
        setError(validationError);
        //set card number 
        props.setValue(e.target.name, e.target.value, validationError === "");
    };

    const matchCreditCardIcon = (creditCardType: number) => {
        switch (creditCardType) {
            case CardType.AMEX:
                return amexIcon;
            case CardType.MASTERCARD:
                return mastercardIcon;
            case CardType.VISA:
                return visaIcon;
            case CardType.DISCOVER:
                return discoverIcon;

            default:
                return genericCardIcon;
        }
    };


    return (
        <>
            <h3>Card Number*</h3>
            <div className={styles.cardNumberWrapper}>
                <input type="text" onChange={e => matchHandler(e)} className={styles.cardNumber} onBlur={e => blurHandler(e)} name='cardNumber' />
                {!(error != "") && matchCreditCardIcon(creditCardType)}
                {(error != "") && <ErrorMsg errors={[error]} />}
            </div>
        </>
    );
};

export default CardInput;
