import { Link } from "gatsby";
import React, { PropsWithChildren, ReactElement } from "react";
import SkeletonBlock from "../../../../components/shared/SkeletonBlock";
import { ILocationProps } from "../interfaces";
import styles from "./HomeClub.module.scss";

const HomeClub = (
    props: PropsWithChildren<ILocationProps>,
): ReactElement => {
    const { location } = props;
    let dayHours;
   if(location)
      dayHours = location.daysHours.length

    return (
        <div className={styles.clubWrapper}>
            {location && (
                <>
                <div className={styles.imageWrapper}>
                    <img src={location.image ? location.image : '/assets/image/ClassPlaceHolder.jpg' } alt={`club-${location.club}`} />
                </div>
                    <div className={styles.info}>
                        <div>
                            <h3>
                                Home Club
                            </h3>
                            <h4 className={styles.club}>{location.club}</h4>
                        </div>
                        <div>
                            <Link to={`/locations/${location.slug}`} className={styles.link}>
                                More about this location
                            </Link>
                        </div>
                        <div>
                            <h4>
                                Address
                            </h4>
                            <p>{location.address.addressLine1}</p>
                            <p>{location.address.provinceState}</p>
                        </div>
                        <div>
                            <h4>
                                {dayHours && location.daysHours[0].days}
                            </h4>
                            <p>{dayHours && location.daysHours[0].hours}</p>
                        </div>
                        <div>
                            <h4>
                                Phone number
                            </h4>
                            <p>{location.phoneNumber}</p>
                        </div>
                        <div>
                           <h4>
                           {dayHours && dayHours > 1 && location.daysHours[1].days}
                            </h4>
                            <p>{dayHours && dayHours > 1 && location.daysHours[1].hours}</p>
                        </div>
                        <div>
                          
                        </div>
                        <div>
                           <h4>
                           {dayHours && dayHours > 2 && location.daysHours[2].days}
                            </h4>
                            <p>{dayHours && dayHours > 2 && location.daysHours[2].hours}</p>
                        </div>
                    </div>
                </>
            )}
            {!location && (
                <>
                    <div>
                        <SkeletonBlock
                            rows={1}
                            styles={{ height: "100px", margin: "0" }}
                        />
                    </div>
                    <div className={styles.info}>
                        <div>
                            <h3>
                                Home Club
                            </h3>
                            <SkeletonBlock rows={1} />
                        </div>
                        <div>
                        <SkeletonBlock rows={1} />
                        </div>
                        <div>
                            <h4>
                                Address
                            </h4>
                            <SkeletonBlock rows={1} />
                        </div>
                        <div>
                            <SkeletonBlock rows={2} />
                        </div>
                        <div>
                            <h4>
                                Phone number
                            </h4>
                            <SkeletonBlock rows={1} />
                        </div>
                        <div>
                            <SkeletonBlock rows={2} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default HomeClub;
