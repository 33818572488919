import React, { useState, useContext} from 'react'
import WebAccountService from '../../../services/WebAccountService';
import Spinner from "../../../components/shared/Spinner";
import { navigate } from "gatsby"
import { UserDataContext } from '../../../contexts/DependencyContext';
import styles from "./login.module.scss";
import NotificationModal from '../../Members_page/Shared/NotificationModal';

interface LoginProps {
    pageData: any;
    navigate: any;
}
interface Popup {
    title: string;
    message: string;
}


const Login = (props: any) => {
    const { pageData, setRenderComponent } = props


    const [formRegisterValues, setFormRegisterValues] = useState({
        'userid': '',
        'password': ''
    });
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [validation, setValidation] = useState<boolean>(true);
    const [popup, setPopup] = useState<Popup | null>(null);

    const { setUserData } = useContext(UserDataContext);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormRegisterValues({ ...formRegisterValues, [name]: value })
    };

    const handleSubmit = async (event) => {
        const formData = { ...formRegisterValues };
        try {
            event.preventDefault();
            setIsLoad(true);
            const response = await WebAccountService.login(
                {
                    userId: formData.userid,
                    password: formData.password
                }
            );
            if (response.success) {
                setUserData({
                    'image': response.response.image,
                    'name': response.response.name,
                    'roleId': response.response.roleId,
                    'status': response.response.status
                })
                setIsLoad(false);
                navigate("/members/dashboard")
            } else {
                setIsLoad(false);
                setPopup({
                    title: "Something went wrong",
                    message: response.message,
                });
            }
        } catch (event) {
            setValidation(true);
        }
    };

    const handleComponent = (e: any, numb: number) => {
        e.preventDefault();
        setRenderComponent(numb)
    }

    return (
        <>
            <div className={styles.forms} >
                <form onSubmit={handleSubmit} className={styles.form}>

                    <ul className={styles.inputWrapper}>
                        <div className={styles.title}>
                            <h2>{pageData?.login_title[0].text}</h2>
                        </div>

                        <li>
                            <input
                                placeholder='username'
                                onChange={handleChange}
                                name='userid'
                                value={formRegisterValues.userid}
                                type="text"
                                id="userid"
                                className={styles.input}
                            />
                            <label htmlFor="userid">
                                username
                            </label>
                            <a href="#" onClick={(e) => handleComponent(e, 3)}>Forgot your username?</a>
                        </li>

                        <li>
                            <input
                                placeholder='password'
                                onChange={handleChange}
                                name='password'
                                value={formRegisterValues.password}
                                type="password"
                                id="password"
                                className={styles.input}
                            />
                            <label htmlFor="confirm password">
                                password
                            </label>
                            <a href="#" onClick={(e) => handleComponent(e, 2)}>Forgot your password?</a>
                        </li>

                        <li>
                            <button
                                disabled={!formRegisterValues.userid && !formRegisterValues.password ? true : false}
                                className={styles.button}
                            >
                                Log in
                            </button>
                            <p>Don’t have an account? <a href="#" onClick={(e) => handleComponent(e, 1)}>Register.</a></p>
                        </li>
                    </ul>
                </form >
            </div >
                {popup && (
                    <NotificationModal title={popup.title} message={popup.message} handleClose={() => setPopup(null)}>
                    </NotificationModal>
                )}
            {isLoad && <Spinner />}
        </>
    )
}

export default Login