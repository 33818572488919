import React, { ReactElement } from "react";
import { useRecaptchaScript } from "../../../../../hooks/useRecaptchaScript";
import styles from "./RecaptchaComponent.module.scss";

interface RecaptchaProps {
    recaptchaError?: boolean;
}

const RecaptchaComponent = (props: RecaptchaProps): ReactElement => {
    useRecaptchaScript();

    return (
        <>
            <div className={styles.recaptchaWrapper}>
                <div
                    className="g-recaptcha"
                    data-sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                />

                {props.recaptchaError && (
                    <small className="errorMessage">Please verify that you're human and try again</small>
                )}
            </div>
        </>
    );
};

export default RecaptchaComponent;




