import React, {
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState
} from "react";
import CardInput from "../../../../components/shared/CardInput";
import CustomSelect from "../../../../components/shared/CustomSelect";
import CvvInput from "../../../../components/shared/CVVInput";
import ExpirationInput from "../../../../components/shared/ExpirationInput";
import Amount from "./Amount";
import { stateOptions } from "../../../Account_create_page/constants/states";
import styles from "./PayDueModal.module.scss";
import { IPayDueRequest } from "../../../../services/interfaces/IPayDue";
import WebAccountService from "../../../../services/WebAccountService";
import { IReferMessage } from "../../Dashboard/Interfaces";
import CustomCheckbox from "../../../../components/shared/CustomCheckbox";
import PrivacyPolicy from "./PrivacyPolicy";
import { IControlValidation } from "../../../../components/shared/General/interfaces";
import UnauthorizedModal from "../UnauthorizedModal";

interface IPayDueProps {
    amount: number | null,
    paymentLoad: (param: boolean | IReferMessage) => void;
}

const PayDueModal = (
    props: PropsWithChildren<IPayDueProps>,
): ReactElement => {

    const { amount, paymentLoad } = props;
    // let controlsValidations: IControlValidation[] = [{ name: "cardNumber", valid: false }, { name: "cvv", valid: false }, { name: "expiration", valid: false }];

    //#region States
    const [payDisabled, setPayDisabled] = useState<boolean>(true);
    const [controlsValidations, setControlsValidations] = useState<IControlValidation[]>([{ name: "cardNumber", valid: false }, { name: "cvv", valid: false }, { name: "expiration", valid: false }, { name: "nameOnCard", valid: false }]);
    const [cardNumber, setCardNumber] = useState<string>("");
    const [formData, setFormData] = useState<IPayDueRequest>({
        amount: amount ?? null as any,
        creditCard: null as any,
        address: null as any,
        storeCard: false as any,
        acceptTermsAndConditions : false
    })
    const [serviceHttpStatusCode, setServiceHttpStatusCode] = useState<number>(0)
    //#endregion

    //#region Credit Card handlers
    const setCreditCard = (name: string, value: string, valid: boolean): void => {
        let validation = controlsValidations.find(x => x.name === name);
        if (validation) {
            validation.valid = valid;
            setControlsValidations([...controlsValidations.filter(x => x.name !== name), validation as IControlValidation]);
        }
        if (!value) {
            return;
        }
        switch (name) {
            case 'expiration':
                expirationHandler(value)
                break;
            case 'cardNumber':
                cardNumberHandler(name, value)
                break;
            default:
                setFormData({
                    ...formData, creditCard: {
                        ...formData.creditCard,
                        [name]: value
                    }
                })
                break;
        }
        if (name === 'expiration' && value.length === 7) {
            let separeteArray = value.split('/')
            setFormData({
                ...formData, creditCard: {
                    ...formData.creditCard,
                    "expirationMonth": separeteArray[0],
                    "expirationYear": separeteArray[1]
                }
            })
        } else {

        }
    }

    const expirationHandler = (value: string): void => {
        if (!(value.length === 7)) return;
        let separeteArray = value.split('/')
        setFormData({
            ...formData, creditCard: {
                ...formData.creditCard,
                "expirationMonth": separeteArray[0],
                "expirationYear": separeteArray[1]
            }
        })
    }

    const cardNumberHandler = (name: string, value: string): void => {
        let valueWithoutSpaces = value.replaceAll(" ", "")
        setCardNumber(value);
        setFormData({
            ...formData, creditCard: {
                ...formData.creditCard,
                [name]: valueWithoutSpaces,
            }
        })
    }
    //#endregion    

    //#region Address handlers
    const setAddress = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFormData({
            ...formData, address: {
                ...formData.address,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleSelectChange = (name: string, value: string): void => {
        //customSelect needs "name" param, but we use state for name
        setFormData({
            ...formData, address: {
                ...formData.address,
                state: value
            }
        })
    };
    //#endregion

    //#region

    useEffect(() => {
        if (!validateControls() || !formData.creditCard?.expirationMonth || !formData.creditCard?.expirationYear ||
            !formData.address?.address1 || !formData.address?.city || !formData.address?.state || !formData.address?.zipCode || !formData.acceptTermsAndConditions) {
            setPayDisabled(true);
            return;
        }

        setPayDisabled(false);
    }, [formData, controlsValidations]);

    const validateControls = (): boolean => {
        for (const item of controlsValidations) {
            if (!item.valid)
                return false;
        }
        return true;
    }

    //#endregion

    const storeCard = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFormData({
            ...formData, [e.target.name]: !formData.storeCard
        })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        paymentLoad(true);
        const payment = await WebAccountService.payDue({
            ...formData
        })

        if (payment.success) {
            paymentLoad({
                success: true,
                message: 'We’ve sent you an email with all the details.'
            })
        } else {
            setServiceHttpStatusCode(payment.httpStatusCode);
            paymentLoad({
                success: false,
                message: payment.message
            });
        }
    }

        const acceptTerms = (e: React.ChangeEvent<HTMLInputElement>): void => {
            setFormData({
                ...formData, [e.target.name]: !formData.acceptTermsAndConditions
            });
        }

    return (
        <form className={styles.payDueForm}>
            <div className={styles.cardWrapper}>
                <CardInput setValue={setCreditCard} />
                <div className={styles.expAndCvv}>
                    <CvvInput
                        cardNumber={cardNumber}
                        setValue={setCreditCard}
                    />
                    <ExpirationInput setValue={setCreditCard} />
                </div>
                <div className={styles.nameOnCard}>
                    <h3>NAME ON CARD*</h3>
                    <input type="text" onChange={(e) => setCreditCard(e.target.name, e.target.value, e.target.value ? true : false)} name='nameOnCard' />
                </div>
            </div>
            <div className={styles.billingAddress}>
                <h2>BILLING ADDRESS</h2>
                <div className={styles.address}>
                    <h3>Address 1*</h3>
                    <input type="text" onChange={setAddress} name='address1' />
                </div>
                <div className={styles.address}>
                    <h3>Address 2</h3>
                    <input type="text" onChange={setAddress} name='address2' />
                </div>
                <div className={styles.address}>
                    <h3>City*</h3>
                    <input type="text" onChange={setAddress} name='city' />
                </div>
                <div className={styles.dualInput}>
                    <div className={styles.state}>
                        <h3>State*</h3>
                        <CustomSelect
                            name={"State"}
                            options={stateOptions}
                            placeholder={"Choose One"}
                            handleChange={handleSelectChange} />
                    </div>
                    <div className={styles.zip}>
                        <h3>ZIP*</h3>
                        <input type="text" onChange={setAddress} name='zipCode' />
                    </div>
                </div>
            </div>
            <CustomCheckbox name={"storeCard"} label={"Store card details for future billing"} handleChange={storeCard as any} />
            <PrivacyPolicy />
            <CustomCheckbox name={'acceptTermsAndConditions'} label={'Acept terms and conditions'} handleChange={acceptTerms as any} />
            {amount && amount != 0 && <Amount amount={amount} />}
            <button onClick={(e) => handleSubmit(e)} className={`${styles.payButton} ${payDisabled ? styles.disable : ''}`} disabled={payDisabled}>Pay</button>
            <UnauthorizedModal httpStatusCode={serviceHttpStatusCode} />
        </form>
    );
};

export default PayDueModal;
