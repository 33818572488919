import React, {
    PropsWithChildren,
    ReactElement,
    useEffect,
    useRef,
} from "react";
import { useEventListener } from "../../../hooks/UseEventListener";
import { closeMenuIcon } from "../../../shared/icons";
import styles from "./Modal.module.scss";

interface ModalStyles
{
    width?: number;
    height?: number;
    borderRadius?: number;
    overflow?:string;
}

interface ModalProps
{
    styles?: ModalStyles;
    handleClose: () => void;
    type?:string;
    errorCreateAccount?:boolean
}

const Modal = (props: PropsWithChildren<ModalProps>): ReactElement =>
{
    const modalRef = useRef<HTMLDivElement>(null as any);

    const handleKeyClose = (e: KeyboardEvent): void =>
    {
        if (e.keyCode === 27 && props.children) props.handleClose();
    };

    const handlePointerDown = (e: PointerEvent): void =>
    {
        if (!modalRef.current.contains(e.target as HTMLElement))
            props.handleClose();
    };

    useEffect(() =>
    {
        if (!props.children) return;

        document.body.classList.add("no-overflow");
        return () => document.body.classList.remove("no-overflow");
    }, [props.children]);

    useEventListener("keydown", handleKeyClose);
    useEventListener("click", handlePointerDown);

    return (
        <div
            className={styles.wrapper}
            style={{
                ["--modalWidth" as string]: props.styles?.width && `${props.styles?.width}px`,
                ["--modalHeight" as string]: props.styles?.height && `${props.styles?.height}px`,
                ["--modalBorderRadius" as string]: props.styles?.borderRadius && `${props.styles?.borderRadius}px`,
                ["--modalOverflow" as string]: props.styles?.overflow && `${props.styles?.overflow}`
            }}
        >
            <div ref={modalRef} className={`${styles.content} ${props.type === "video" ? styles.video :"" } ${props.errorCreateAccount ? styles.errorModal : ""}`}>
                <button onClick={props.handleClose} className={styles.close}>
                    {props.errorCreateAccount ? 'Ok' : closeMenuIcon }
                </button>
                {props.errorCreateAccount && <span>{closeMenuIcon} </span>}
                {props.children}
            </div>
        </div>
    );
};

export default Modal;
