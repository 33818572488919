export interface Cards {
    list: Array<ICard>;
}

interface ICard {
    brand: string;
    image: string;
    verification: string;
    separation: string;
    hidden: string;
    accepted: boolean;
    length: number;
    type: CardType
}

export enum CardType {
    AMEX = 0,
    MASTERCARD = 1,
    VISA = 2,
    DISCOVER = 3,
    UNKNOWN = 99
}
