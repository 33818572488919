import React from 'react';
import styles from './Item.module.scss'
import { dateHandler, handleSplit } from '../../../../shared/helpers';

const Item = (data: any) => {
    const { description, start, end, location, studio, instructor } = data;

    const currentDate = new Date(start);
    const currentProps = dateHandler(currentDate);
    const { dayName, numberDate, monthName } = currentProps;
    let cutDate = numberDate.split("/")[1];

    const startDateSplitted = new Date(start).toLocaleTimeString("en-US", { timeZone: process.env.TIME_ZONE });
    const formatStartDate = handleSplit(startDateSplitted);
    const endDateSplitted = new Date(end).toLocaleTimeString("en-US", { timeZone: process.env.TIME_ZONE });
    const formatEndtDate = handleSplit(endDateSplitted);

    const instructorName = instructor?.split(" ") || [];
    for (let i = 0; i < instructorName.length; i++) {
        instructorName[i] = instructorName[i].charAt(0).toUpperCase() + instructorName[i].slice(1);
    };
    const intructorFormat = instructorName.join(' ');


    return (
        <>
            <div className={styles.pastClass}>
                <h3>
                    <span>{dayName.short}, {monthName.short} {cutDate}</span>
                    <span>{description}</span>
                </h3>
                <span className={styles.gridRow}><strong className={styles.gridStrong}>When:</strong>{formatStartDate} - {formatEndtDate}</span>
                <span className={styles.gridRow}><strong className={styles.gridStrong}>Where:</strong>{location} {studio}</span>
                <span className={styles.gridRow}><strong className={styles.gridStrong}>With:</strong>{intructorFormat}</span>
            </div>
        </>
    )
}

export default Item;