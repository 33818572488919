class ApplicationBrowserDetectionService {
    /**
     * Checks if the browser is Safari
     */
    isSafari = () => navigator.vendor &&
                     navigator.vendor.indexOf("Apple") > -1 &&
                     navigator.userAgent &&
                     navigator.userAgent.indexOf("CriOS") === -1 &&
                     navigator.userAgent.indexOf("FxiOS") === -1;

    isFirefox = () => navigator.userAgent.indexOf("Firefox") > -1;
}

const BrowserDetectionService = new ApplicationBrowserDetectionService();
export default BrowserDetectionService;