import { isNull, isNumber } from "lodash";
import { useDependency } from "../../../contexts/DependencyContext";
import BaseApiService from "../../../services/BaseApiService";
import { IAsyncFormatter } from "../General/interfaces";

export class EmailFormatter implements IAsyncFormatter {

    current: string;
    prev: string;
    erasing: boolean;
    maxLength: number;

    constructor() {
        this.current = "";
        this.prev = "";
        this.erasing = false;
        this.maxLength = 14;
    }
    format(val: string): string {
        throw new Error("Method not implemented.");
    }

    async validate(val: string): Promise<string> {
        let res = await BaseApiService.post("validate/email", { email: val });
        if (res.data.isValid) {
            return "";
        }
        else{
            return "We couldn't verify your email address, please check your input";
        }
    }

    setValue(val: string, character: string = ""): void {
        this.prev = this.current;
        this.current = val + character;

        if (this.prev.length > this.current.length) {
            this.erasing = true;
        } else {
            this.erasing = false;
            const lastIsNumber = !isNaN(Number(val[val.length - 1]));

            if (!lastIsNumber && val.length != 4 && val.length != 5 && val.length != 10) {
                this.current = this.prev;
            }
        }

        if (this.current.length === this.maxLength) {
            this.prev = this.current;
        }
    }
}