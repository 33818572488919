import React, { ChangeEvent, PropsWithChildren, ReactElement, useEffect, useState } from "react";
import CustomCheckbox from "../CustomCheckbox";
import ErrorMsg from "../ErrorMsg";
import { EmailFormatter } from "./emailinfo";
import styles from './EmailInput.module.scss'

interface IEmailInputProps {
    setValue(name: string, value: string, valid: boolean): void;
    defaultValue?: string;
    className?: string;
    placeholder?: string;
}

const EmailInput = (props: PropsWithChildren<IEmailInputProps>): ReactElement => {
    const formatter = new EmailFormatter();
    const [error, setError] = useState<string>("");
    const [showMessage, setShow] = useState(true)

    const matchHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setValue(e.target.name, e.target.value, false);
    };

    const blurHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let targetValue = e.target.value;
        let targetName: string = e.target.name;

        if (!targetValue) {
            setError("");
            return;
        }

        // -- Validate value
        formatter.validate(e.target.value).then((validationError: string) => {
            props.setValue(targetName, targetValue, validationError === "");
            setError(validationError);
        });
    };

    const checkChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setShow(!e.target.checked);
        props.setValue("email", props.defaultValue as string, e.target.checked);
    }

    useEffect(() => {
        // -- Clean error if email is null or empty
        if (!props.defaultValue)
            setError("");
        setShow(true);
    }, [props.defaultValue]);


    return (
        <>
            <div className={styles.emailWrapper}>
                <input type="text"
                    name="email"
                    id="email"
                    className={`${props.className ? props.className : styles.email}`}
                    onBlur={e => blurHandler(e)}
                    onChange={matchHandler}
                    value={props.defaultValue}
                    placeholder={`${props.placeholder ? props.placeholder : ''}`}
                />
                {(error != "") && showMessage && (
                    <>
                        <ErrorMsg errors={[error]} />
                    </>
                )}
            </div>
            {(error != "") && (
                <CustomCheckbox name={"notifyNews"} label={"I confirm that this email is correct."} handleChange={checkChange as any} />
            )}
        </>
    );
};

export default EmailInput;
