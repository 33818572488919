import React from 'react';
import { errorFilterIcon } from "../../../../shared/icons";
import styles from "./Advice.module.scss";

export const Advice = () => {
    return (
        <div className={styles.AdviceWrapper}>
            <div className={styles.advice}>
                {errorFilterIcon}
                <p>There are no past classes with these filter options.</p>
                <p>Try with other dates!</p>
            </div>
        </div>
    )
}