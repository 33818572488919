import React, { useState, useEffect, PropsWithChildren, ReactElement, forwardRef } from 'react';
import SkeletonBlock from '../../../components/shared/SkeletonBlock'
import styles from './PastClasses.module.scss';
import WebAccountService from "../../../services/WebAccountService";
import Item from "./Item/Item"
import { dateHandler } from '../../../shared/helpers';
import { Advice } from './Advice';
import { Link } from "gatsby"
import UnauthorizedModal from '../Shared/UnauthorizedModal';
import { downloadAlt } from '../../../shared/icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomInputDate } from '../Shared/CustomInputDate/index'
import Helmet from '../../../components/Helmet';
import { IPastClasses } from '../../../services/interfaces/IPastClasses';



interface Date {
    dateFrom: any;
    dateTo: any;
}

const PastClasses = (props: PropsWithChildren<any>): ReactElement => {

    const [dateData, setDateData] = useState<Date>({
        dateFrom: '',
        dateTo: ''
    });

    const [data, setData] = useState<Array<IPastClasses>>([]);
    const [printSkeleton, setPrintSkeleton] = useState(true);
    const [haveCheckins, setHaveCheckins] = useState(false);
    const [serviceHttpStatusCode, setServiceHttpStatusCode] = useState<number>(0);

    useEffect(() => {
        const currentDate = new Date()
        const currentProps = dateHandler(currentDate);
        const formatCurrentDate = currentProps.fullNumberDate;
        const pastDate = new Date(new Date().setDate(new Date().getDate() - 7));
        const pastProps = dateHandler(pastDate);
        const formatPastDate = pastProps.fullNumberDate;
        setDateData({ ...dateData, dateFrom: pastDate, dateTo: currentDate });
    }, [])

    const handleData = async (event: any) => {
        // -- Skip first call
        if (dateData.dateFrom === "" || dateData.dateFrom === "") {
            return;
        }

        try {
            setPrintSkeleton(true);
            const response = await WebAccountService.pastClasses(
                {
                    dateFrom: dateData.dateFrom,
                    dateTo: dateData.dateTo
                }
            );

            if (response.success && response.response.length >= 1) {
                setHaveCheckins(true);
                setData(response.response);
            } else {
                if (response.httpStatusCode !== 401) {
                    setHaveCheckins(false);
                }
                else {
                    setServiceHttpStatusCode(response.httpStatusCode);
                }
            }
        } catch (error) {
            console.log(error);
        }
        finally {
            setPrintSkeleton(false);
        }
    };

    useEffect(() => {
        handleData(dateData);
    }, [dateData]);

    const handleChangeStart = (e: any) => {
        setDateData({ ...dateData, dateFrom: e })
    }

    const handleChangeEnd = (e: any) => {
        setDateData({ ...dateData, dateTo: e })
    }

    const businessUnitCode = localStorage.getItem("businessUnitCode")
    const scheduleLink = businessUnitCode ? `/scheduler?businessUnitCode=${businessUnitCode}` : "/scheduler";

    return (
        <>
            <Helmet title={'Past Classes'} />
            <div className={`${styles.pastClasses} print-block`}>
                <div className={styles.container}>
                    <h2 className={styles.title}>Past Classes</h2>
                    <div className={` ${styles.buttonContainer} print-hidden`}>
                        <div className={styles.linkContainer}>
                            <Link className={styles.button} to={scheduleLink}>Find a Class</ Link >
                        </div>
                        <div className='print-hidden'>
                            <button className={styles.button} onPointerDown={() => window ? window.print() : null}>
                                {downloadAlt}Download</button>
                        </div>
                    </div>
                </div>
                <div className={`${styles.inputContainer} print-hidden`}>
                    <div>
                        <label htmlFor="dateFrom" style={{ display: 'block' }}>From</label>
                        <DatePicker
                            selected={dateData.dateFrom}
                            onChange={handleChangeStart}
                            customInput={<CustomInputDate />}
                            portalId="root-portal"
                        />
                    </div>
                    <div>
                        <label htmlFor="dateTo" style={{ display: 'block' }}>To</label>
                        <DatePicker
                            selected={dateData.dateTo}
                            onChange={handleChangeEnd}
                            customInput={<CustomInputDate />}
                            portalId="root-portal"
                        />
                    </div>
                </div>
                {
                    printSkeleton ? (
                        <>
                            <div className={styles.pastClassSkeleton}>
                                <div>
                                    <div><SkeletonBlock rows={1} /></div>
                                    <div> <SkeletonBlock rows={1} /></div>
                                </div>
                                <div className={styles.gridRow}><div className={styles.gridStrong}><SkeletonBlock rows={1} /></div><SkeletonBlock rows={2} /></div>
                                <div className={styles.gridRow}><div className={styles.gridStrong}><SkeletonBlock rows={1} /></div><SkeletonBlock rows={2} /></div>
                                <div className={styles.gridRow}><div className={styles.gridStrong}><SkeletonBlock rows={1} /></div><SkeletonBlock rows={2} /></div>
                            </div>
                        </ >
                    ) : (
                        !haveCheckins ? (
                            <Advice />
                        ) : (
                            <>
                                {
                                    data.map((data, index) => (
                                        <Item {...data} key={index} />
                                    ))
                                }
                            </>
                        )
                    )
                }
            </div >
            <UnauthorizedModal httpStatusCode={serviceHttpStatusCode} />
        </>
    )
}


export default PastClasses;