import React, { ChangeEvent, FormEvent, ReactElement, useEffect } from "react";
import FormFactory from "./FormFactory";
import ReviewOrder from "../ReviewOrder";
import styles from "./FormFactory.module.scss";
import RecaptchaComponent from "./RecaptchaComponent";

interface FormProps {
    onStepClick: (arg0: string) => void;
    currentStep: number;
    formData: object;
    steps: Array<object>;
    membership: object;
    handleInputChange: (e: ChangeEvent) => void;
    handleSelectChange: (element: object, option: object) => void;
    handleSubmit?: (e: FormEvent) => void;
    errors?: object;
    recaptchaError?:boolean;
}

const Form = (props: FormProps): ReactElement => {
    const step = props.steps[props.currentStep];
    const lastStepIndex = Object.keys(props.steps).length - 1;
    const lastStep = props.currentStep === lastStepIndex;

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            e.preventDefault();
            props.onStepClick("next");
        }
    };

    return (
        <>
            <h1 className={styles.stepTitle}>{step.name}</h1>

            {
                step.review && <ReviewOrder stepsSnapshot={props.formData} membership={props.membership} />
            }

            <form onSubmit={e => e.preventDefault()} className={styles.formContainer} onKeyDown={handleKeyDown} noValidate>
                {
                    step &&
                    <FormFactory
                        key={step.index}
                        errors={props.errors}
                        {...{ step }}
                        stepFormData={props.formData[step.index]}
                        handleChange={props.handleInputChange}
                        handleSelectChange={props.handleSelectChange}
                    />
                }
                {lastStep && ( <RecaptchaComponent recaptchaError={props.recaptchaError}/>
                )}
                <div className={styles.submitWrapper}>
                    {
                        step.index > 0 && (
                            <button
                                className={styles.backButton}
                                onClick={() => props.onStepClick("back")}>
                                BACK
                            </button>
                        )
                    }
                    <button
                        className={styles.nextButton}
                        onClick={() => props.onStepClick("next")}>
                        {lastStep ? step?.actionButtonName : "next"}
                    </button>
                </div>
            </form>
        </>
    );
};

export default Form;
