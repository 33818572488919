import React, { useRef } from "react";
import styles from "./TermsAndConditions.module.scss"

export const TermsAndConditions = ({ data, callback }) => {
    const scrollableElemRef = useRef(null);
    const read = useRef(false);

    React.useEffect(() => {
        if (scrollableElemRef.current?.scrollHeight === scrollableElemRef.current?.clientHeight)
            callback();
    }, []); 

    const handleScroll = (e: any) => {
        if (!read.current) {
            const scrollOffset = Math.round(e.target.scrollHeight - e.target.scrollTop);
            const elementHeight = Math.round(e.target.clientHeight);
            
            const bottom = scrollOffset === elementHeight;
                
            if (bottom) {
                read.current = true;
                callback();
            }
        }
    };

    return (
        <div className={styles.termsAndConditionsText}  onScroll={handleScroll}>
            <h3>{data.title[0].text}</h3>
            <div ref={scrollableElemRef}>
            {
                data.terms.map((term, idx) => <p key={idx}>{term.text}</p>)
            }
            </div>
        </div>
    )
};
