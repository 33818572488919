
import React, { PropsWithChildren, ReactElement } from 'react';
import { useState } from 'react';
import ErrorMsg from '../ErrorMsg';
import { CardExpFormatter } from './expirationinfo';
import styles from './ExpirationInput.module.scss'

interface IExpirationInputProps {
    setValue(name:string, value: string, valid: boolean): void;
}

const ExpirationInput = (props: PropsWithChildren<IExpirationInputProps>): ReactElement => {
    const formatter = new CardExpFormatter();
    const [error, setError] = useState<string>("");

    const matchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let formattedValue = formatter.format(e.target.value);
        e.target.value = formattedValue;        
    };

    const blurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let msg = formatter.validate(e.target.value);
        // -- Validate value
        setError(msg);
        props.setValue(e.target.name, e.target.value, msg === "");
    };
    return (
        <div>
        <h3>Expiration Date*</h3>
            <div className={styles.expirationWrapper}>
                <input type="text" onChange={e => matchHandler(e)} className={styles.expiration} onBlur={e => blurHandler(e)} name='expiration' />
                {(error != "") && <ErrorMsg errors={[error]} />}
            </div>
        </div>
    );
}

export default ExpirationInput