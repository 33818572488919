import BrowserDetectionService from "./BrowserDetectionService";

class EventByBrowserService 
{
    public static getEventVanilla(event: string): string {
        const isSafari = BrowserDetectionService.isSafari();
        const isFirefox = BrowserDetectionService.isFirefox();
        switch (event) {
            case "pointerdown":
                if (isSafari || isFirefox) event = "mousedown";
                break;
        
            default:
                break;
        }

        return event;
    };

    public static getEventJsx(event: string): string {
        const isSafari = BrowserDetectionService.isSafari();
        const isFirefox = BrowserDetectionService.isFirefox();
        switch (event) {
            case "onPointerDown":
                if (isSafari || isFirefox) event = "onMouseDown";
                break;
        
            default:
                break;
        }

        return event;
    };
}

export default EventByBrowserService;