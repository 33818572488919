import React, { useState } from 'react'
import Spinner from '../../../components/shared/Spinner';
import WebAccountService from '../../../services/WebAccountService';
import styles from "./register.module.scss";
import { information, arrows } from "../../../shared/icons";
import NotificationModal from '../../Members_page/Shared/NotificationModal';

interface IRegister {
    roleId: string;
    value: string;
};
interface Popup {
    title: string;
    message: string;
    success: boolean;
};

const Register = (props: any) => {
    const { setRenderComponent } = props;

    const [formRegisterValues, setFormRegisterValues] = useState({
        'roleId': '',
        'userId': ''
    });
    const [popup, setPopup] = useState<Popup | null>(null);
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.Input);

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormRegisterValues({ ...formRegisterValues, [name]: value })
    };

    const handleSubmit = async (event: any) => {
        const formData = { ...formRegisterValues };
        try {
            event.preventDefault();
            setIsLoad(true);
            const response = await WebAccountService.createUser(
                {
                    roleId: formData.roleId,
                    userId: formData.userId
                }
            );
            setIsLoad(false);
            if (response.success) {
                setComponentState(ComponentState.Success);
            }
            else if (response.response.hasUser) {
                setComponentState(ComponentState.HasUser);
            }
            else {
                setPopup({
                    title: "Something went wrong",
                    message: response.message,
                    success: false
                });
            }
        } catch (event) {
            setComponentState(ComponentState.Input);
            setIsLoad(false);
        }
    };

    const handleComponent = (e: any, numb: number) => {
        e.preventDefault();
        setRenderComponent(numb)
    }

    return (
        <>
            {componentState === ComponentState.Input &&
                <div className={styles.forms} >
                    <div className={styles.backWrapper}>
                        <a href="#" onClick={(e) => handleComponent(e, 0)}>{arrows.left}Back to login</a>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <ul className={styles.inputWrapper}>
                            <div className={styles.title}>
                                <h2>Register!</h2>
                            </div>
                            <p>Register by entering the information below.</p>

                            <li>
                                <input
                                    placeholder='MEMBER ID OR KEY TAG'
                                    onChange={handleChange}
                                    name='roleId'
                                    value={formRegisterValues.roleId}
                                    type="text"
                                    id="roleId"
                                    className={styles.input}
                                />
                                <label htmlFor="roleId">
                                    <span>member id or key tag</span>
                                    <div className={styles.tooltip}>
                                        {information}
                                        <span className={styles.tooltipItem}>Contact your club if<br />you don't have your ID</span>
                                    </div>
                                </label>
                            </li>

                            <li>
                                <input
                                    placeholder='USERNAME'
                                    onChange={handleChange}
                                    name='userId'
                                    value={formRegisterValues.userId}
                                    type="text"
                                    id="username"
                                    className={styles.input}
                                />
                                <label htmlFor="username">
                                    username
                                </label>
                            </li>

                            <li>
                                <button
                                    disabled={!formRegisterValues.roleId && !formRegisterValues.userId ? true : false}
                                    className={styles.button}
                                >
                                    SUBMIT
                                </button>
                            </li>
                        </ul>
                    </form >
                </div >
            }
            {componentState === ComponentState.Success &&
                <div className={styles.forms} >
                    <form>
                        <h2>Check your email</h2>
                        <p>Please validate your account by clicking on the link we sent to your email address. The email has been sent to the email address associated with your member account. If you do not receive an email within 10 minutes, please call our club for assistance and to validate the email on file.</p>
                        <p>You have a time limit of 12 hours for validation.</p>
                        <a href="#" onClick={(e) => handleComponent(e, 0)}>Back to login</a>
                    </form >
                </div >
            }
            {componentState === ComponentState.HasUser &&
                <div className={styles.forms} >
                    <form>
                        <h2>Something went wrong</h2>
                        <p>You already have an username. You can recover your username <a href="#" onClick={(e) => handleComponent(e, 3)}>here.</a></p>
                        <a href="#" onClick={(e) => handleComponent(e, 0)}>Back to login</a>
                    </form >
                </div >
            }
            {popup &&
                <NotificationModal title={popup.title} message={popup.message} handleClose={() => setPopup(null)}>
                </NotificationModal>}
            {isLoad && <Spinner />}
        </>
    )
}

enum ComponentState {
    Input = 1,
    Success = 2,
    HasUser = 3
}

export default Register;