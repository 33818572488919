import React from "react"
import { Router, Redirect } from "@reach/router"
import { withPrefix } from 'gatsby'
import PrivateRoute from "../components/PrivateRoute";
import MemberDashboard from "../templates/Members_page/Dashboard";
import AccountCreatePage from "../templates/Account_create_page";
import AccountCreateSuccess from "../templates/Account_create_success";
import CheckIns from "../templates/Members_page/Check_ins_page";
import Invoices from "../templates/Members_page/Invoices";
import PastClasses from "../templates/Members_page/Past_Classes_page";
import RestorePass from "../templates/Members_page/RestorePass";
import FreezeMembership from "../templates/Members_page/FreezeMembership";
import CancelMembership from "../templates/Members_page/CancelMembership";
import UpcomingClasses from "../templates/Members_page/Upcoming_Classes_Page";
import ResetPassword from "../templates/Members_page/ResetPassword";
import RedirectDefault from "../components/RedirectDefault";
import { isWindow } from "../shared/helpers";
import RegisterAndLogin from "../templates/Register_and_LogIn";
import CreatePass from "../templates/Register_and_LogIn/createPass";
import MemberInfo from "../templates/Members_page/MemberInfo";
import PaymentMethod from "../templates/Members_page/PaymentMethod";
import MembershipInformation from "../templates/Members_page/MembershipInformation";


const ClientApp = () => (
    <Router basepath={withPrefix("/members")}>
        <AccountCreatePage path="/create" />
        <AccountCreateSuccess path="/create/success" />
        <RestorePass path="/restore-password" />
        <CreatePass path="/create-password" />
        <RegisterAndLogin path="/login" />
        <Redirect from='/' to="login" />
        {/* Private Routes */}
        <PrivateRoute component={MemberDashboard} path="/dashboard" location={isWindow ? location : ""} />
        <PrivateRoute component={CheckIns} path="/check-ins" location={isWindow ? location : ""} />
        <PrivateRoute component={Invoices} path="/invoices" location={isWindow ? location : ""} />
        <PrivateRoute component={PastClasses} path="/past-classes" location={isWindow ? location : ""} />
        <PrivateRoute component={UpcomingClasses} path="/upcoming-classes" location={isWindow ? location : ""} />
        <PrivateRoute component={FreezeMembership} path="/freeze" location={isWindow ? location : ""} />
        <PrivateRoute component={CancelMembership} path="/cancel" location={isWindow ? location : ""} />
        <PrivateRoute component={ResetPassword} path="/reset-password" location={isWindow ? location : ""} />
        <PrivateRoute component={PaymentMethod} path="/payment" location={isWindow ? location : ""} />
        <PrivateRoute component={RedirectDefault} path="/404" location={isWindow ? location : ""} default />
        <PrivateRoute component={MemberInfo} path="/info" location={isWindow ? location : ""} />
        <PrivateRoute component={MembershipInformation} path="/membership" location={isWindow ? location : ""} />
    </Router>
);

export default ClientApp;