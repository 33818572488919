import React, { PropsWithChildren, ReactElement } from "react";
import MembersModal from "../MembersModal";
import styles from './ConfirmationModal.module.scss';

interface IConfirmationModalProps {
    handleYes(): void;
    handleNo(): void;
    text: string;
    title?: string;
    yesButton?: string;
    noButton?: string;
}

const ConfirmationModal = (props: PropsWithChildren<IConfirmationModalProps>): ReactElement => {

    return (
        <MembersModal title={props.title ? props.title : ""} handleClose={props.handleNo}>
            <h3>{props.text}</h3>
            <div className={styles.btns}>
                <button onClick={props.handleYes}>{props.yesButton || "yes"}</button>
                <button onClick={props.handleNo}>{props.noButton || "no"}</button>
            </div>
        </MembersModal>
    )
}

export default ConfirmationModal;