import React, { PropsWithChildren, ReactElement, useRef, useState } from "react";
import SkeletonBlock from "../../../../components/shared/SkeletonBlock";
import WebAccountService from "../../../../services/WebAccountService";
import { IFeedbackModuleProps } from "../Interfaces";
import styles from "./Feedback.module.scss";

const Feedback = (props: PropsWithChildren<IFeedbackModuleProps>): ReactElement => {
    const {
        rate,
        feedback,
        link,
        setMessage
    } = props;

    let displayNone;

    if (rate && link === null) {
        displayNone = true
    }


    const [formFeedback, setFormFeedback] = useState<string>('');
    const textRef = useRef<HTMLTextAreaElement>(null);

    const feedbackHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFormFeedback(e.target.value)
    }

    const handleSentFeedback = async (e: any) => {
        e.preventDefault()

        setMessage(true)
        const refer = await WebAccountService.contact({ text: formFeedback })

        if (refer.success) {
            setMessage({
                success: true,
                message: 'Thanks for your feedback'
            });
            if (textRef.current) textRef.current.value = ""
        } else {
            setMessage({
                success: false,
                message: refer.message
            })
        }
    }

    return (
        <div className={`${styles.feedbackWrapper} ${(!rate || !feedback) && styles.skeleton} ${displayNone ? styles.displayNone : ''}`}>
            {(!rate || !feedback || !link) ?
                <>
                    <div className={styles.rateUs}>
                        <SkeletonBlock rows={1} styles={{ height: '30px' }} />
                        <SkeletonBlock rows={3} />
                        <SkeletonBlock rows={1} styles={{ width: '30%' }} />
                    </div>
                    <div className={styles.feedback}>
                        <SkeletonBlock rows={1} styles={{ height: '30px' }} />
                        <SkeletonBlock rows={2} />
                        <SkeletonBlock rows={1} styles={{ height: '100px' }} />
                        <div className={styles.skeletonBtnRight}>
                            <SkeletonBlock rows={1} styles={{ align: 'flex-end', width: '30%' }} />
                        </div>
                    </div>
                </>
                :
                <>
                    <div className={styles.rateUs}>
                        <h2 className={styles.title}>{rate.title[0].text}</h2>
                        <p className={styles.description}>{rate.description[0].text}</p>
                        <a className={styles.button} href={link}> <button className={styles.button} >{rate.buttonText[0].text}</button></a>
                    </div>
                    <form className={styles.feedback}>
                        <h2 className={styles.title}>{feedback.title[0].text}</h2>
                        <p className={styles.description}>{feedback.description[0].text}</p>
                        <textarea className={styles.textArea} placeholder='Leave us your message here!' onChange={(e) => feedbackHandler(e)} ref={textRef} />
                        <a className={styles.button}> <button type='submit' className={styles.button} onClick={handleSentFeedback}>Submit</button></a>
                    </form>
                </>
            }
        </div>);
};

export default Feedback;
