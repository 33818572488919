import React, {
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from "react";
import { genericCardIcon } from "../../../shared/icons";
import { CardType } from "../CardInput/Interfaces";
import ErrorMsg from "../ErrorMsg";
import { CvvFormatter } from "./cvvinfo";
import styles from "./CVVInput.module.scss";

interface ICvvInputProps {
    cardNumber: string;
    setValue(name: string, value: string, valid: boolean): void;
}

const CvvInput = (
    props: PropsWithChildren<ICvvInputProps>,
): ReactElement => {

    let formatter = new CvvFormatter(props.cardNumber);
    const [error, setError] = useState<string>("");

    const matchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let formattedValue = formatter.format(e.target.value);

        e.target.value = formattedValue;
    };

    const blurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let msg = formatter.validate(e.target.value);
        // -- Validate value
        setError(msg);

        props.setValue(e.target.name, e.target.value, msg === "");
    };

    useEffect(() => {
        if(document && document.querySelector('#cvv') && (document.querySelector('#cvv') as HTMLInputElement).value != "")
            setError(formatter.validate((document.querySelector('#cvv') as HTMLInputElement).value)); 
    }, [props.cardNumber])

    return (
        <div>
            <h3>CVV*</h3>
            <div className={styles.cvvWrapper}>
                <input
                    type="text"
                    onChange={e => matchHandler(e)}
                    className={styles.cvv}
                    onBlur={e => blurHandler(e)}
                    id='cvv'
                    name='cvv'
                />
                {!(error != "") && genericCardIcon}
                {error != "" && <ErrorMsg errors={[error]} />}
            </div>
        </div>
    );
};

export default CvvInput;
