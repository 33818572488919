import React, {
    PropsWithChildren,
    ReactElement,
} from "react";
import {
    cardsInfo,
} from "../../../../components/shared/CardInput/cardsInfo";
import { CardType } from "../../../../components/shared/CardInput/Interfaces";
import SkeletonBlock from "../../../../components/shared/SkeletonBlock";
import { dateHandler } from "../../../../shared/helpers";
import { IBillDataProps } from "../interfaces";
import styles from "./BillData.module.scss";

const BillData = (
    props: PropsWithChildren<IBillDataProps>,
): ReactElement => {
    const { bill } = props;

    let date;

    if (bill) {
        date = bill.paymentDueDate
            ? dateHandler(new Date(bill.paymentDueDate))
            : null;
    }


    const splitCard = (cardNumb: string) => {
        if (!bill) return;
        let cardType;

        switch (bill.type) {
            case "Visa":
                cardType = cardsInfo.list.find(card => card.type === CardType.VISA)
                break;
            case "Mastercard":
                cardType = cardsInfo.list.find(card => card.type === CardType.MASTERCARD)
                break;
            case "AmericanExpress":
                cardType = cardsInfo.list.find(card => card.type === CardType.AMEX)
                break;
            case "Discover":
                cardType = cardsInfo.list.find(card => card.type === CardType.DISCOVER)
                break;
            default:
                cardType = cardsInfo.list.find(card => card.type === CardType.VISA)
                break;
        }

        if (cardType) {
            if (bill.type === "AmericanExpress")
                cardNumb = cardNumb.slice(1)

            let regex = new RegExp(cardType.separation).exec(cardNumb.replaceAll('*', '0'))
            let finalSeparation: string = '';

            if (!regex) return;
            for (let i = 1; i < regex.length; i++) {

                if (typeof regex[i] !== "undefined") {

                    const regexNumb = (i === regex.length - 1) ? replaceFirstNumber(regex[i]) : regex[i].replaceAll('0', 'x');

                    if (regex[i])
                        finalSeparation = finalSeparation + ' ' + regexNumb

                }
            }
            return finalSeparation
        }
    };

    const replaceFirstNumber = (number: string) => {

        if (number.length > 4) return 'x' + number.slice(1)
        else return number

    }

    return (
        <div className={styles.dataWrapper}>
            {bill && (
                <>
                    <div>
                        <h4>Next Bill</h4>
                        <p>{bill.amount}</p>
                        <p>
                            {date?.monthName.large} {date?.dayNumber}
                        </p>
                    </div>
                    {
                        bill.card && <div>
                            <h4>Billed to</h4>
                            <p>{splitCard(bill.card)}</p>
                            <p>
                                Exp{" "}
                                {bill.expirationMonth < 10
                                    ? `0${bill.expirationMonth}`
                                    : bill.expirationMonth}
                                /{bill.expirationYear}
                            </p>
                            <span
                                className={`${bill.isvalid
                                        ? styles.displayNone
                                        : styles.invalid
                                    }`}
                            >
                                Invalid
                            </span>
                        </div>
                    }
                </>
            )}
            {!bill && (
                <>
                    <div>
                        <h4>Next Bill</h4>
                        <SkeletonBlock
                            rows={2}
                            styles={{ width: "100%" }}
                        />
                    </div>
                    <div>
                        <h4>Billed to</h4>
                        <SkeletonBlock rows={3} />
                    </div>
                </>
            )}
        </div>
    );
};

export default BillData;
