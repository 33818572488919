import React, { useState, PropsWithChildren, ReactElement, useEffect } from 'react'
import WebAccountService from '../../../services/WebAccountService';
import Spinner from "../../../components/shared/Spinner";
import styles from "./RestorePass.module.scss";
import MembersModal from '../Shared/MembersModal';
import { navigate } from 'gatsby';
import Helmet from '../../../components/Helmet';

interface Popup {
    title: string;
    message: string;
    success: boolean;
};

const RestorePass = (
    props: PropsWithChildren<any>,
): ReactElement => {
    const [formRegisterValues, setFormRegisterValues] = useState({
        'password': '',
        'confirmPassword': '',
        'token': ''
    });
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [popup, setPopup] = useState<Popup | null>(null);
    const [wrongPass, setWrongPass] = useState<boolean>(false)


    useEffect(() => {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let token = url.searchParams.get("token");
        setFormRegisterValues({ ...formRegisterValues, token: token ? token : "" })
    }, [])

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormRegisterValues({ ...formRegisterValues, [name]: value })
    };

    const handleSubmit = async (event: any) => {
        const formData = { ...formRegisterValues };
        try {
            event.preventDefault();

            if (formData.password !== formData.confirmPassword) {
                setPopup({
                    title: "Verify the information",
                    message: "Passwords don't match",
                    success: false
                });

                return;
            }


            setIsLoad(true);
            const response = await WebAccountService.restorePassword(
                {
                    password: formData.password,
                    token: formData.token
                }
            );
            setIsLoad(false);
            if (response.success) {
                setPopup({
                    title: "Success",
                    message: "Your password have been updated",
                    success: true
                });
            }
            else {
                setPopup({
                    title: "Something went wrong",
                    message: response.message,
                    success: false
                });
            }
        } catch (event) {
            setIsLoad(false);
        }
    };

    const handlePopUpClose = () => {
        if (popup?.success) {
            navigate("/members");
        }
        else {
            setPopup(null);
        }
    }

    useEffect(() => {
        if(formRegisterValues.confirmPassword !== formRegisterValues.password ){
             setWrongPass(true)
         }
         else{
             setWrongPass(false)
         }
    }, [formRegisterValues.confirmPassword])


    return (
        <>
        <Helmet title={'Restore your Password'}/>
            <div className={styles.forms} >
                <form onSubmit={handleSubmit}>
                    <ul>
                        <div className={styles.titleContainer}>
                            <h2 className={styles.title}>Reset Password</h2>
                        </div>
                        <li>
                            <input
                                placeholder='password'
                                onChange={handleChange}
                                name="password"
                                value={formRegisterValues.password}
                                type="password"
                                id="password"
                                className={styles.input}
                            />
                            <label htmlFor="password">
                                password
                            </label>
                        </li>
                        <li>
                            <input
                                placeholder='confirm password'
                                onChange={handleChange}
                                name="confirmPassword"
                                value={formRegisterValues.confirmPassword}
                                type="Password"
                                id="confirmPassword"
                                className={`${styles.input} ${wrongPass ? styles.wrong : ""}`}
                            />
                            <label htmlFor="confirmPassword">
                                confirm password
                            </label>
                            {wrongPass && <small className={styles.wrongPass}>Those passwords didn´t match. Try again.</small>}
                        </li>
                        <li>
                            <button
                                disabled={(!formRegisterValues.password && !formRegisterValues.confirmPassword && wrongPass ) ? true : false}
                                className={`${styles.button} ${(!formRegisterValues.password || !formRegisterValues.confirmPassword || wrongPass )? styles.disabled : ""}`}
                            >
                                SUBMIT
                            </button>
                        </li>
                    </ul>
                </form >
            </div >
            {isLoad && <Spinner />}
            {popup &&
                <MembersModal title={popup.title} handleClose={handlePopUpClose}>
                    {popup.message}
                </MembersModal>}
        </>
    )
}

export default RestorePass;