export const fallBackTexts = {
    cancelMembership : [{
        type: '',
        text: 'Things just not working out? We hope you’ll reconsider, but in the meantime, click the button below to request to cancel your membership with us. A manager will reach out to assist you with the process.',
        spans: []
    }],
    cancelMembershipConfirmation :[ {
        type: '',
        text: 'Are you sure you want to cancel your membership?',
        spans: []
    }],
    freezeMembership :[ {
        type: '',
        text: 'Looking to put a pause on your workouts? No matter how long you’ll be gone, we hope to see you back! Click the button below to request a freeze on your membership with us, and a manager will reach out to assist you with the process.',
        spans: []
    }],
    freezeMembershipConfirmation :[ {
        type: '',
        text: 'Are you sure you want to freeze your membership for 30 days?',
        spans: []
    }],
    resetPass : [{
        type: '',
        text: 'Forgot your password? Don’t miss out! Click the button below to receive an email with instructions on resetting your account password.',
        spans: []
    }],
    feedback: {
        title: [
            {
                type: "",
                text: "Have feedback for our team, or general questions on our club? Let’s connect!",
                spans: [],
            },
        ],
        description : [
            {
                type: "",
                text:"Leave us a message below and our general manager will be in contact shortly.",
                spans: [],
            },
        ]
    },
    rate: {
        title: [
            {
                type: "",
                text:"Like the club?",
                spans: [],
            },
        ],
        description : [
            {
                type: "",
                text: "Leave us a review by clicking the button below.",
                spans: [],
            },
        ],
        buttonText : [
            {
                type: "",
                text: "Review",
                spans: [],
            },
        ]
    },
    refer: {
        refer_title: [
            {
                type: "",
                text:"Refer a friend",
                spans: [],
            },
        ],
        refer_desc : [
            {
                type: "",
                text: "Enter your friend's email information below and get a referral bonus applied towards your membership!",
                spans: [],
            },
        ],
    }
    
}

