import React, { PropsWithChildren, ReactElement, useState } from "react";
import {
    amexIcon,
    discoverIcon,
    genericCardIcon,
    mastercardIcon,
    visaIcon,
} from "../../../shared/icons";
import styles from './PhoneNumberInput.module.scss'
import { PhoneNumFormatter } from "./phonenumberinfo";
import ErrorMsg from "../ErrorMsg";

interface IPhoneNumberInputProps {
    setValue(name: string, value: string, valid: boolean): void;
    defaultValue?: string;
    className?: string;
    placeholder?: string;
}

const PhoneNumberInput = (props: PropsWithChildren<IPhoneNumberInputProps>): ReactElement => {
    const formatter = new PhoneNumFormatter();
    const [error, setError] = useState<string>("");

    let skipFormat = false;

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // -- Prevent formatting the phone number
        skipFormat = e.key === "Backspace";
    };

    const matchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (skipFormat) {
            props.setValue(e.target.name, e.target.value, false);
            return;
        }

        let formattedValue = formatter.format(e.target.value);
        let targetName: string = e.target.name;

        props.setValue(targetName, formattedValue, false);
    };

    const blurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let formattedValue = formatter.format(e.target.value);
        let targetName: string = e.target.name;

        // -- Validate value
        formatter.validate(e.target.value).then((validationError: string) => {
            props.setValue(targetName, formattedValue, validationError === "");
            setError(validationError);
        });
    };


    return (
        <>
            <div className={styles.phoneNumberWrapper}>
                <input
                    type="text"
                    name="phone"
                    id="phone"
                    className={`${props.className ? props.className : styles.phoneNumber}`}
                    onChange={e => matchHandler(e)}
                    onKeyDown={e => onKeyPress(e)}
                    onBlur={e => blurHandler(e)}
                    placeholder={`${props.placeholder ? props.placeholder : "(xxx) xxx-xxxx"}`}
                    value={props.defaultValue} />
                {(error != "") && <ErrorMsg errors={[error]} />}
            </div>
        </>
    );
};

export default PhoneNumberInput;
