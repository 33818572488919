import React, { ChangeEvent, Fragment, ReactElement, PropsWithChildren, useState, useContext, useEffect, useRef } from "react";

import { Step } from "../../interfaces/step";
import { FormElement } from "../../interfaces/form-element";
import { FormElementOption } from "../../interfaces/form-element-option";

import CustomSelect from "../../../../templates/Account_create_page/components/CustomSelect";

import styles from "./FormFactory.module.scss";
import "./Override.css";

import { AccountCreateContext } from "../../index";
import Modal from "../../../../components/shared/Modal";

import formatters from "./formatters";
import SpinnerOnValidationLoad from "./SpinnerOnValidationLoad";
import ValidInput from "./ValidInput";
import ErrorMsg from "./ErrorMsg";
import CreditCardActive from "./CreditCardActive";

import { cardsInfo } from "./formatters/CardNumFormatter"
import { isEmpty } from "lodash";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputDatePicker from "./InputDatePicker";
import { range } from "lodash";
import { getMonth, getYear } from "../../../../shared/helpers";
import moment from "moment";
import EventByBrowserService from "../../../../services/EventByBrowserService";
import BrowserDetectionService from "../../../../services/BrowserDetectionService";

export const regExps = {
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    string: /^[A-Za-z.\s_-]+$/,
    tel: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,}$/im
};

interface FormFactoryProps {
    step: Step;
    handleChange: (e: ChangeEvent) => void;
    handleSelectChange: (element: object, option: object) => void;
};

const remoteValidationTypes = ["email", "phone"];

const years = range(1921, getYear(new Date()) + 1, 1);
const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

const InputContainer = (props: PropsWithChildren<any>): ReactElement => {
    const [showChildren, setShowChildren] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [disabled, setDisabled] = useState(
        props.element.disabled || false
    );
    const [validatingInput, setValidatingInput] = useState(false)
    const [validInput, setValidInput] = useState(false)
    const [creditCardType, setCreditCardType] = useState(4)
    const _reveal = useRef(null);

    const context = useContext(AccountCreateContext);

    useEffect(() => {
        const { reuse, value, name } = props.element;
        if (!reuse || !props.stepFormData) return;
        context.prefillValues(reuse, value, name);
    }, [props.stepFormData]);

    const handleChange = (e: ChangeEvent) => {
        const { type, reveal, reuse, formatter, name, actions } = props.element;

        if (type === "select") {
            props.handleChange(e);
        } else if (type === "checkbox" || type === "radio") {
            const event = {
                currentTarget: {
                    name: e.target.name,
                    value: e.target.checked
                }
            };

            if (reveal) {
                setShowChildren(e.target.value);
            } else if (actions) {
                context.handleActions(props.element, event.currentTarget.value);
            }

            props.handleChange(event, props.element);
        } else {
            let event = e;

            if (formatter) {
                const formatterObj = formatters[formatter];
                const Formatter = formatterObj.class;
                let formatterInstance;

                if (formatterObj.instances[name]) {
                    formatterInstance = formatterObj.instances[name];
                } else {
                    formatterObj.instances[name] = new Formatter();
                    formatterInstance = formatterObj.instances[name];
                }

                event = {
                    currentTarget: {
                        name: e.target.name,
                        value: formatterInstance.format(e.target.value)
                    }
                };
            }

            if (actions) {
                if (showChildren && actions.reset) {
                    setShowChildren(false);
                    _reveal.current = null;
                }
            }

            props.handleChange(event);
        }

        if (name === "card_number") {
            let card = e.target.value
            let match = false
            for (let i = 0; i < cardsInfo.list.length; i++) {
                if (card.match(new RegExp(cardsInfo.list[i].verification))) { setCreditCardType(i), match = true }
            }
            if (!match) setCreditCardType(4)
        }
    };

    const handleDate = (element, value) => {

        const event = {
            currentTarget: {
                name,
                value
            }
        };
            
        props.handleChange(event);

        setValidatingInput(true);

        element.value = value;

        context.validate(element, (isValid, errorKeys) => {
            setValidatingInput(false);
            if (remoteValidationTypes.includes(element.name)) setValidInput(isValid);

            if (!isValid && errorKeys[0] === "remote" && element.name === "email") {
                const revealElement = element.validations["remote"].onError.reveal;
                _reveal.current = revealElement;
                setShowChildren(true);
            }
        })
    };

    const renderOptionLabel = (element: any, option: any): string | ReactElement => {
        let label = option.label || element.label;

        if (element.link) {
            const [noLinkText, linkText] = label.split("+");

            label = (
                <>
                    {noLinkText}
                    <span
                        className={styles.labelLinks}
                        onClick={() => setShowModal(true)}>
                        {linkText}
                    </span>
                </>
            )
        }

        if (element.label) {
            const [noLinkText, linkText] = label.split("+");

            label = (
                <>
                    {noLinkText}
                    <span
                        className={styles.labelLinks}
                    >
                        {linkText}
                    </span>
                </>
            )
        }

        return label;
    };

    const renderModal = () => {
        const ModalData = props.element.link.data;

        return (
            <>
                {
                    showModal && <Modal handleClose={() => setShowModal(false)}>
                        {typeof ModalData === "function" ? <ModalData fromForm data={context.termsAndConditions} /> : ModalData}
                    </Modal>
                }
            </>
        )
    };

    const forceValidation = (element) => {
        setValidatingInput(true);

        context.validate(element, (isValid, errorKeys) => {
            setValidatingInput(false);
            if (remoteValidationTypes.includes(element.name)) setValidInput(isValid);
        });
    };

    function handleSetDisabled(element: any) {
        if (!element) return;

        const { name } = element;
        const disabledElement = document.getElementsByName(name);
        disabledElement.checked = false; //reset the checkbox
        setDisabled(false);
    }

    const setFormElements = (element: FormElement): ReactElement => {
        const getExtraProps = () => {
            return {
                onBlur: () => {
                    if (element.value == null || isEmpty(element.value))
                        return

                    setValidatingInput(true);

                    context.validate(element, (isValid, errorKeys) => {
                        setValidatingInput(false);
                        if (remoteValidationTypes.includes(element.name)) setValidInput(isValid);

                        if (!isValid && errorKeys[0] === "remote" && element.name === "email") {
                            const revealElement = element.validations["remote"].onError.reveal;
                            _reveal.current = revealElement;
                            setShowChildren(true);
                        }
                    });
                }
            };
        };

        const hasErrors = props.errors?.length > 0;
        const className = [styles[element.type], hasErrors && !validatingInput ? styles.hasErrors : "", validInput ? styles.checkedInput : ""].join(" ");

        const setInputComponent = () => {
            switch (element.type) {
                case "text":
                case "password":
                case "email":
                    return (
                        <div className={styles.formElement}>
                            <label htmlFor={element.name}>{element.label}</label>
                            <div>
                                <input
                                    type={element.type}
                                    name={element.name}
                                    value={element.value || ""}
                                    className={className}
                                    onChange={handleChange}
                                    placeholder={element.placeholder}
                                    {...getExtraProps()}
                                />
                                {validatingInput && <SpinnerOnValidationLoad />}
                                {!validatingInput && <ErrorMsg errors={props.errors} />}
                                <ValidInput />
                                {!(props.errors.length > 0) && element.name === "card_number" && <CreditCardActive type={creditCardType} />}
                            </div>
                        </div>
                    );

                case "number":
                    return (
                        <div className={styles.formElement}>
                            <label htmlFor={element.name}>{element.label}</label>
                            <div>
                                <input
                                    type={element.type}
                                    value={element.value || ""}
                                    name={element.name}
                                    className={className}
                                    onChange={handleChange}
                                    {...getExtraProps()}
                                />
                                <ErrorMsg errors={props.errors} />
                            </div>
                        </div>
                    );

                case "select":
                    return (
                        <div className={styles.formElement}>
                            <label htmlFor={element.name}>{element.label}</label>
                            <div>
                                <CustomSelect
                                    options={element.options.map(o => { o.name = o.label; return o; })}
                                    value={element.value}
                                    {...getExtraProps()}
                                    handleChange={(option) => props.handleSelectChange(element, option)}
                                    classes={{
                                        container: className,
                                        errors: hasErrors && styles.hasErrors,
                                        optionsContainer: styles.optionsContainer,
                                        option: styles.option,
                                        arrow: styles.arrow
                                    }}
                                />
                                <ErrorMsg errors={props.errors} />
                            </div>
                        </div>
                    );


                case "checkbox":
                case "radio":
                    const handlePointerDown = { 
                        [EventByBrowserService.getEventJsx("onPointerDown")]: () => {if (disabled) forceValidation(element)}
                    };
                    return (
                        <div className={styles.formElement}>
                            {element.options?.map((option: FormElementOption, i: number) => (
                                <Fragment key={i}>
                                    <div 
                                        className={`${element.centerContent ? styles.centered : ''}`}
                                        {...handlePointerDown}
                                        >
                                        <div className={styles.checkboxFormWrapper}>
                                            <input
                                                type={element.type}
                                                name={element.name}
                                                checked={!disabled && element.value}
                                                hasdisabledattr={disabled ? "disabled" : ""}
                                                className={`${styles.checkboxForm} ${disabled ? styles.disabled : ""}`}
                                                onChange={!disabled ? handleChange : () => {}}
                                                {...getExtraProps()}
                                            />
                                            <label htmlFor={element.name}>{renderOptionLabel(element, option)}</label>
                                        </div>
                                    </div>
                                </Fragment>
                            ))}
                            {props.errors.map((x: string, i: number) => <span key={i} className={styles.errorCheckbox}>{x}</span>)}
                        </div>
                    );

                case "textarea":
                    return (
                        <div className={styles.formElement}>
                            <label htmlFor={element.name}>{element.label}</label>
                            <div>
                                <textarea
                                    name={element.name}
                                    rows={5}
                                    value={element.value || ''}
                                    onChange={handleChange}
                                    className={className}
                                    {...getExtraProps()}
                                />
                                <ErrorMsg errors={props.errors} />
                            </div>
                        </div>
                    );

                case "date":
                    return (
                        <div className={styles.formElement}>
                            <label htmlFor={element.name}>{element.label}</label>
                            <div className={styles.datePickerWrapper}>
                                <DatePicker
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                    }) => (
                                        <div
                                            style={{
                                                margin: 10,
                                                display: "flex",
                                                justifyContent: "space-evenly"
                                            }}
                                        >
                                            <select
                                                value={getYear(date)}
                                                onChange={({ target: { value } }) => changeYear(value)}
                                                className={styles.inputSelect}
                                            >
                                                {years.map(option => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <select
                                                value={months[getMonth(date)]}
                                                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                                                className={styles.inputSelect}
                                            >
                                                {months.map(option => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}

                                    selected={element.value ? moment(element.value).toDate() : null}
                                    onChange={(date) => handleDate(element, date)}
                                    placeholderText="mm/dd/yyyy"
                                    {...getExtraProps()}
                                    strictParsing
                                    //customInput={<InputDatePicker elementPicker={element} handleDate={handleDate} extraClass={className}/>}
                                />
                                <ErrorMsg errors={props.errors} />
                            </div>
                        </div>
                    );

                default:
                    return <></>
            }

        }


        const renderMetaComponent = () => {
            if (!props.element.component)
                return null;

            const Component = props.element.component;
            const propName = props.element.propName;

            return <Component data={context[propName]} callback={() => handleSetDisabled(props.element)} />;
        };


        return (
            <>
                {renderMetaComponent()}

                {setInputComponent()}

                {
                    showChildren ? (
                        <>
                            {
                                _reveal.current.map((elementName: string, i: number) => {
                                    const element = props.stepFormData[elementName];

                                    return (
                                        <div style={{ marginTop: 30 }}>
                                            <InputContainer
                                                key={i}
                                                errors={[]}
                                                element={element}
                                                elements={props.step.formElements}
                                                step={props.step}
                                                stepFormData={props.stepFormData}
                                                handleChange={props.handleChange}
                                                handleSelectChange={props.handleSelectChange} />
                                        </div>
                                    )
                                })
                            }
                        </>
                    ) : null
                }
            </>
        )
    };

    const mustShow = props.element.toggle ? !props.stepFormData[props.element.toggle]?.value : true;

    return (
        <>
            <div className={
                `${styles.inputContainer} ${props.element.inline ? styles.inline : ""} 
                 ${!mustShow ? styles.hideInput : ""}
                `}>
                {setFormElements(props.element)}
            </div>

            {props.element.link?.type === "modal" && renderModal()}
        </>
    )
};

const FormFactory = (props: FormFactoryProps): ReactElement => {
    const { errors } = props;

    return (
        <>
            {Object.keys(props.step.formElements)
                .filter((element: any) => !props.step.formElements[element].abstract)
                .map((element: any, i: number) => {
                    const lastErrors =
                        errors[element] && errors[element].length ?
                            [errors[element][errors[element].length - 1]] :
                            [];

                    return (
                        <InputContainer
                            key={i}
                            errors={lastErrors || []}
                            element={props.stepFormData[element]}
                            elements={props.step.formElements}
                            step={props.step}
                            stepFormData={props.stepFormData}
                            handleChange={props.handleChange}
                            handleSelectChange={props.handleSelectChange} />
                    )
                })}
        </>
    );
};

export default FormFactory;
