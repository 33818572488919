import React from "react";
import SkeletonBlock from "../../../../components/shared/SkeletonBlock";
import { Inews } from "../Interfaces";
import styles from "./News.module.scss";

interface newsProps {
    news: Inews | null,
}

const News = (props: newsProps) => {
    const { news } = props;

    let displayNone;
    if (news && !news.news_content[0].text) {
        displayNone = true
    }

    return (
        <div
            className={`${styles.newsWrapper} ${news ? " " : styles.skeleton} ${displayNone ? styles.displayNone : ''}`}
            data-tag="new"
        >
            {news && (
                <>
                    {news.news_image.url && <img
                        src={news.news_image.url}
                        alt=""
                    />}
                    <div className={styles.news}>
                        {news.news_title[0] && news.news_title[0].text && <h3> {news.news_title[0].text} </h3>}
                        {news.news_content[0] && news.news_content[0].text && <p> {news.news_content[0].text} </p>}
                    </div>
                </>
            )}
            {!news && (
                <>
                    <SkeletonBlock rows={1} styles={{ height: '5em', margin: 0 }} />
                    <div className={styles.news}>
                        <SkeletonBlock rows={1} styles={{ width: '20%', margin: '' }} />
                        <SkeletonBlock rows={3} styles={{ width: '90%', align: 'flex-start' }} />
                    </div>
                </>
            )}
        </div>
    );
};

export default News;
