import { Link } from "gatsby";
import React, { PropsWithChildren, ReactElement } from "react";
import SkeletonBlock from "../../../../components/shared/SkeletonBlock";
import { dateHandler, handleSplit } from "../../../../shared/helpers";
import { IupcomingProps } from "../Interfaces";
import styles from "./Upcoming.module.scss";


const Upcoming = (props: PropsWithChildren<IupcomingProps>): ReactElement => {
    const { upcomingClass } = props;

    let date;
    let startDate : string | null = null;
    let endDate : string | null = null ;
    let displayNone : boolean = true;

    if(upcomingClass){
        date = upcomingClass.startDate ? dateHandler(new Date(upcomingClass.startDate)) : null;
        startDate = upcomingClass.startDate ? new Date(upcomingClass.startDate).toLocaleTimeString("en-US", { timeZone: process.env.TIME_ZONE }) : null ;
        endDate = upcomingClass.endDate ? new Date(upcomingClass.endDate).toLocaleTimeString("en-US", { timeZone: process.env.TIME_ZONE }) : null ;
        if(!upcomingClass.startDate)
            displayNone = false 
    }

    const statusClass = (code :number ) =>{
        switch (code) {
            case 1:
                return styles.confirmed;
            case 2:
                return styles.waiting;
            case 3:
                return styles.cancelled;
            default:
                break;
        }
    }
    return (
        <div className={`${styles.upcoming} ${upcomingClass && statusClass(upcomingClass.statusCode)} ${upcomingClass ? "" : styles.skeleton } ${displayNone ? '' : styles.displayNone}`}>
            {upcomingClass && (
                <>
                    <div className={styles.imageContainer}>
                        <img src={ upcomingClass.classImage ? upcomingClass.classImage : "/assets/image/ClassPlaceHolder.jpg"} alt=""/>
                    </div>
                    <div className={styles.info}>
                        <h3> Upcoming class </h3>
                        <p> {upcomingClass.description} </p>
                        <p> {date?.dayName.short} {date?.dayNumber} {handleSplit(startDate)} - {handleSplit(endDate)} </p>
                        <Link
                        className={styles.goSite}
                        to={'/members/upcoming-classes'}>See more</Link>
                    </div>
                </>
            )}
            {!upcomingClass && (
                <>
                    <SkeletonBlock
                        rows={1}
                        styles={{
                            width: "37%",
                            height: "100%",
                            margin: 0,
                        }}
                    />
                    <div className={styles.info}>
                    <SkeletonBlock 
                        rows={3} 
                        styles={{
                            width: '80%',
                            align: 'flex-start'
                    }}/>
                    <SkeletonBlock 
                        rows={1} 
                        styles={{
                            width: '30%'
                    }}/>
                    </div>
                </>
            )}
        </div>
    );
}

export default Upcoming

