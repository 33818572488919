import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import CustomCheckbox from '../../../../../components/shared/CustomCheckbox';
import SkeletonBlock from '../../../../../components/shared/SkeletonBlock';
import { IPrismicText } from '../../../../../components/Widgets/TwoSidesImageBanner/Interfaces/IData';
import PrismicService from '../../../../../services/PrismicService';
import styles from './PrivacyPolicy.module.scss';


const PrivacyPolicy = (props : PropsWithChildren<any>,
    ): ReactElement => {

    const [ privacy, setPrivacy] = useState<any>()
    const getPrismicData = async () => {
        const prismicInstance = await PrismicService.getInstance();
        const dashboardContent = await prismicInstance.getDocuments(
            "dashboard_content",
        );
        return dashboardContent.results;
    };

    const handlePrivacyPolicy = async () =>{
        const prismicDashboard = await getPrismicData();
        if (
            prismicDashboard &&
            prismicDashboard[0] &&
            prismicDashboard[0].data
        ) {
            setPrivacy(
                prismicDashboard[0].data.privacy_policy
            )
               
        }
    }

    useEffect(() => {
        handlePrivacyPolicy();
    }, [])
    
    return (
        <div className={styles.privacyWrapper}>
            <h3 className={styles.title}>Terms and conditions</h3>
            <p className={styles.privacyText}>*you must read and accept the terms and conditions</p>
            <div className={styles.privacy}> 
            {
                privacy && privacy.map((privacy:IPrismicText, i:number)=>(
                    <p key={i}>{privacy.text}</p>
                ))

            }
            {
                !privacy &&
                <SkeletonBlock rows={4}/>
            }
            </div>
        </div>
    )
}

export default PrivacyPolicy
