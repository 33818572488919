import React, { useState } from 'react';
import styles from './Item.module.scss';
import { dateHandler, handleSplit } from '../../../../shared/helpers';
import placeHolder from '../../../../../public/assets/image/ClassPlaceHolder.jpg';
import WebAccountService from '../../../../services/WebAccountService';


const Item = (props: any) => {
    const { description, end, start, instructor, location, stateCode, studio, classImage, eventInstanceId } = props.data;

    const currentDate = new Date(start);
    const currentProps = dateHandler(currentDate);
    const { dayName, numberDate, monthName } = currentProps;
    let cutDate = numberDate.split("/")[1];

    const startDateSplitted = new Date(start).toLocaleTimeString("en-US", { timeZone: process.env.TIME_ZONE });
    const formatStartDate = handleSplit(startDateSplitted);
    const endDateSplitted = new Date(end).toLocaleTimeString("en-US", { timeZone: process.env.TIME_ZONE });
    const formatEndtDate = handleSplit(endDateSplitted);

    const instructorName = instructor?.split(" ") || [];
    for (let i = 0; i < instructorName.length; i++) {
        instructorName[i] = instructorName[i].charAt(0).toUpperCase() + instructorName[i].slice(1);
    };
    const intructorFormat = instructorName.join(' ');

    const handleCancelRegistration = async (e: any) => {
        e.preventDefault()
        props.setMessage(true);
        
        const res = await WebAccountService.cancelClassRegistration({
            eventInstanceId: eventInstanceId
        });

        if (res.success) {
            props.setMessage({
                success: true,
                message: 'The class was canceled successfully'
            })
        } else {
            props.setMessage({
                success: false,
                message: res.message
            })
        }
    }

    const handleStateColor = (state: number) => {
        const stateColor: any = {
            1: styles.confirmed,
            2: styles.waitingList,
            3: styles.cancelled
        };
        return stateColor[state] ?? '';
    };

    const handleStateText = (state: number) => {
        const stateText: any = {
            1: 'confirmed',
            2: 'waiting list',
            3: 'cancelled'
        };
        return stateText[state] ?? '';
    };

    return (
        <>
            <div className={styles.class}>
                <span className={`${styles.status} ${handleStateColor(stateCode)}`}>{handleStateText(stateCode)}</span>
                <div className={styles.img}>
                    <img src={classImage ? classImage : placeHolder} alt="" />
                </div>
                <div className={styles.date}>
                    <span>{dayName.short}, {monthName.short} {cutDate}</span>
                    <span> {description}</span>
                </div>
                <div className={`${styles.info} ${stateCode == 1 ? styles.paddingForButton : null}`}>
                    <p><strong>WHEN:</strong> {formatStartDate} - {formatEndtDate}</p>
                    <p><strong>WHERE:</strong> {location} {studio}</p>
                    <p><strong>WITH:</strong> {intructorFormat}</p>
                    {
                        stateCode === 1 ? (
                            <button className={styles.cancelledButton} onClick={(e) => handleCancelRegistration(e)}>✕ cancel</button>
                        ) : null
                    }
                </div>
            </div>
        </>
    )
}

export default Item;