import React, { useState, useEffect, PropsWithChildren, ReactElement } from 'react';
import styles from './FreezeMembership.module.scss';
import WebAccountService from "../../../services/WebAccountService";
import MembersModal from '../Shared/MembersModal';
import ConfirmationModal from '../Shared/ConfirmationModal';
import UnauthorizedModal from '../Shared/UnauthorizedModal';
import PrismicService from '../../../services/PrismicService';
import SkeletonBlock from '../../../components/shared/SkeletonBlock';
import { IPrismicText } from '../../../components/Widgets/TwoSidesImageBanner/Interfaces/IData';
import { fallBackTexts } from '../fallbackTexts';
import Helmet from '../../../components/Helmet';

const FreezeMembership = (props: PropsWithChildren<any>): ReactElement => {
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [serviceHttpStatusCode, setServiceHttpStatusCode] = useState<number>(0);
    const [message, setMessage] = useState<IPrismicText[] | null >(null);
    const [confirmMessage, setConfirmMessage] = useState<IPrismicText[] | null >(null);


    const handleConfirmModal = async () => {
        setConfirm(true);
    }

    const handleNo = async () => {
        setConfirm(false);
    }

    const handleSubmit = async () => {
        setConfirm(false);
        // -- Open spinner
        setIsLoad(true);
        const response = await WebAccountService.freezeMembership();
        // -- Close spinner
        setIsLoad(false);
        if (response.success) {
            setModalTitle("Success");
            setModalMessage("Thank you for your membership freeze request. A manager will be in touch soon to confirm. A fee may apply");
        }
        else {
            if(response.httpStatusCode !== 401){
                setModalTitle("Error");
                setModalMessage(response.message);
            }
            else {
                setServiceHttpStatusCode(response.httpStatusCode);
            }
        }
        setShowModal(true);
    };

    const handleModalClose = async () => {
        setShowModal(false);
    }


    const getPrismicData = async () => {
        const prismicInstance = await PrismicService.getInstance();
        const dashboardContent = await prismicInstance.getDocuments(
            "dashboard_content",
        );
        return dashboardContent.results;
    };

    const handleData = async () =>{
        const prismicMembership = await getPrismicData();

        const infoObj = prismicMembership[0].data.membership_freeze_info;
        if(prismicMembership.length > 0 && infoObj.length > 0 && infoObj[0].text != "") {
            setMessage(prismicMembership[0].data.cancel_membership_info);
        } else {
            setMessage(fallBackTexts.freezeMembership);
        }

        const confirmObj = prismicMembership[0].data.membership_freeze_confirm;
        if(prismicMembership.length > 0 && confirmObj.length > 0 && confirmObj[0].text != "") {
            setConfirmMessage(prismicMembership[0].data.membership_freeze_confirm);
        } else {
            setConfirmMessage(fallBackTexts.freezeMembershipConfirmation);
        }
    }


    useEffect(() => {
        handleData();
    }, []);

    return (
        <>
        <Helmet title={'Freeze your Membership'}/>
            <div className={`${styles.freezeMembership}`}>
                <h2 className={styles.title}>Freeze Membership</h2>

                <ul className={styles.content}>
                    <li>
                    {!message ? 
                    <SkeletonBlock rows={3} /> :
                    <p>{message[0].text}</p>}
                    </li>
                </ul>

                <button className={styles.button} onClick={handleConfirmModal}>Membership<br />freeze request</button>
            </div >
            {isLoad && <MembersModal handleClose={() => setIsLoad(false)} title="loading" loading={true} />}
            {showModal && <MembersModal handleClose={handleModalClose} title={modalTitle}>{modalMessage}</MembersModal>}


            {
                confirm && <ConfirmationModal text={confirmMessage[0].text} handleYes={handleSubmit} handleNo={handleNo}></ConfirmationModal>
            }
            <UnauthorizedModal httpStatusCode={serviceHttpStatusCode} />
        </>
    )
}

export default FreezeMembership;