import React, {
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from "react";
import styles from "./Dashboard.module.scss";
import WebAccountService from "../../../services/WebAccountService";
import Alert from "./Alert";
import Profile from "./Profile";
import Upcoming from "./Upcoming";
import News from "./News";
import ReferFriend from "./ReferFriend";
import {
    IDashboardData,
    IFeedback,
    Inews,
    Iprofile,
    IRate,
    IReferMessage,
    IUpcoming,
} from "./Interfaces";
import PrismicService from "../../../services/PrismicService";
import PayDueModal from "../Shared/PayDueModal";
import MembersModal from "../Shared/MembersModal";
import UnauthorizedModal from "../Shared/UnauthorizedModal";
import Feedback from "./Feedback";
import { fallBackTexts } from "../fallbackTexts";
import Helmet from "../../../components/Helmet";
import NotificationModal from "../Shared/NotificationModal";

const MemberDashboard = (
    props: PropsWithChildren<any>,
): ReactElement => {
    const [
        dashboardLoad,
        setDashboardLoad,
    ] = useState<IDashboardData | null>(null);
    const [news, setNews] = useState<Inews | null>(null);
    const [profile, setProfile] = useState<Iprofile | null>(null);
    const [upcoming, setUpcoming] = useState<IUpcoming | null>(null);
    const [activeModal, setModalActive] = useState<Boolean>(false);
    const [message, setMessage] = useState<IReferMessage | boolean>(false);
    const [feedbackMessage, setfeedbackMessage] = useState<IReferMessage | boolean>(false);
    const [paymentLoad, setPaymentLoad] = useState<IReferMessage | boolean>(false);
    const [serviceHttpStatusCode, setServiceHttpStatusCode] = useState<number>(0);
    const [rate, setRate] = useState<IRate | null>(null);
    const [feedback, setFeedback] = useState<IFeedback | null>(null);
    const [referTexts, setReferTexts] = useState<any>(null)

    const handleData = async () => {
        const res = await WebAccountService.dashboardData();
        if (res.success) {
            const data: IDashboardData = res.response;
            setDashboardLoad(data);
        } else {
            //log out and go register and login
            setServiceHttpStatusCode(res.httpStatusCode);
        }
        const prismicDashboard = await getPrismicData();

        if (
            prismicDashboard &&
            prismicDashboard[0] &&
            prismicDashboard[0].data
        ) {
            setNews({
                news_content: prismicDashboard[0].data.news_content,
                news_image: prismicDashboard[0].data.news_image,
                news_title: prismicDashboard[0].data.news_title,
            });

            try {
                if (
                    typeof prismicDashboard[0].data.rate_description != 'undefined' &&
                    typeof prismicDashboard[0].data.rate_title != 'undefined' &&
                    typeof prismicDashboard[0].data.rate_button != 'undefined'
                ) {
                    setRate({
                        title: prismicDashboard[0].data.rate_title,
                        description: prismicDashboard[0].data.rate_description,
                        buttonText: prismicDashboard[0].data.rate_button,
                    })
                }
                else {
                    setRate(fallBackTexts.rate)
                }

            } catch (error) {
                setRate(fallBackTexts.rate)
            }
            try {
                if (
                    typeof prismicDashboard[0].data.feedback_description != 'undefined' &&
                    typeof prismicDashboard[0].data.feedback_title != 'undefined'
                ) {
                    setFeedback({
                        title: prismicDashboard[0].data.feedback_title,
                        description: prismicDashboard[0].data.feedback_description,
                    })
                } else {
                    setFeedback(fallBackTexts.feedback)
                }
            } catch (error) {
                setFeedback(fallBackTexts.feedback)
            }

            try{
                if (
                    typeof prismicDashboard[0].data.refer_title != 'undefined' &&
                    typeof prismicDashboard[0].data.short_refer_text != 'undefined'
                ){
                setReferTexts({
                    refer_title :  prismicDashboard[0].data.refer_title,
                    refer_desc : prismicDashboard[0].data.short_refer_text
                })
            }
                else {
                    setReferTexts(fallBackTexts.refer)
                }
            }catch(err){
                setReferTexts(fallBackTexts.refer)
            }
        }
    };

    const getPrismicData = async () => {
        const prismicInstance = await PrismicService.getInstance();
        const dashboardContent = await prismicInstance.getDocuments(
            "dashboard_content",
        );
        return dashboardContent.results;
    };

    const paymentModalClose = () => {
        setModalActive(!activeModal);
        let isWindow = typeof window !== "undefined" ? window : null
        if (typeof paymentLoad === 'object' && paymentLoad.success && isWindow) {
            window.location.reload()
        } else {
            setPaymentLoad(false)
        }
    }

    useEffect(() => {
        handleData();
    }, []);

    useEffect(() => {
        if (dashboardLoad) {
            setProfile({
                name: dashboardLoad.name,
                roleid: dashboardLoad.roleId,
                membership: dashboardLoad.membership,
                homeClub: dashboardLoad.homeClub,
                balance: dashboardLoad.balance,
                nextBill: dashboardLoad.nextBill,
                image: dashboardLoad.image,
            });

            setUpcoming(dashboardLoad.upcomingClass);
        }
    }, [dashboardLoad]);

    let noNews = false;
    if (news && !news.news_content[0].text) {
        noNews = true;
    }

    return (
        <>
        <Helmet title={'Member Dashboard'}/>
            <div className={`${styles.dashboard}`}>
                {dashboardLoad && !dashboardLoad.validPaymentMethod && (
                    <Alert />
                )}
                <Profile profileData={profile} activeModal={setModalActive} />
                <Feedback
                    rate={rate}
                    feedback={feedback}
                    link={dashboardLoad ? dashboardLoad.reviewLink : null}
                    setMessage={setfeedbackMessage}
                />
                <Upcoming upcomingClass={upcoming} />
                <News news={news} />
                <ReferFriend
                    texts={referTexts}
                    setMessage={setMessage}
                />
            </div>
            {activeModal && (typeof paymentLoad === 'boolean') && (
                <MembersModal title={typeof paymentLoad === 'object' ? "" : "Credit Card"} handleClose={() => paymentModalClose()} loading={typeof paymentLoad === 'boolean' ? paymentLoad : false}>
                    <PayDueModal amount={profile ? profile.balance : null} paymentLoad={setPaymentLoad} />
                </MembersModal>)
            }
            { activeModal && typeof paymentLoad === 'object' && (
            paymentLoad.success ? 
            <NotificationModal handleClose={() => paymentModalClose()} title={"Success"} span={"Payment complete"} message={"We’ve sent you an email with all the details."} /> 
            :
            <NotificationModal handleClose={() => paymentModalClose()} title={"Something went wrong"}  message={paymentLoad.message} /> 
            )
            }
            {
                message &&
                <MembersModal title={"Refer Friend"} handleClose={() => setMessage(false)} loading={typeof message === 'boolean' ? message : false}>
                    {
                        typeof message === 'object' &&
                        <p> {message.message}</p>
                    }
                </MembersModal>
            }
            {
                feedbackMessage &&
                <MembersModal title={"Feedback"} handleClose={() => setfeedbackMessage(false)} loading={typeof feedbackMessage === 'boolean' ? feedbackMessage : false}>
                    {
                        typeof feedbackMessage === 'object' &&
                        <p> {feedbackMessage.message}</p>
                    }
                </MembersModal>
            }
            <UnauthorizedModal httpStatusCode={serviceHttpStatusCode} />
        </>
    );

};

export default MemberDashboard;
